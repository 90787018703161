import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResponsiveImage from '../Components/ResponsiveImage.js';

import Obraz3798 from '../Images/obrazy/3798.jpg';
import Obraz3797 from '../Images/obrazy/3797.jpg';
import Obraz3796 from '../Images/obrazy/3796.jpg';
import Obraz3795 from '../Images/obrazy/3795.jpg';
import Obraz3794 from '../Images/obrazy/3794.jpg';
import Obraz3793 from '../Images/obrazy/3793.jpg';
import Obraz3792 from '../Images/obrazy/3792.jpg';
import Obraz3789 from '../Images/obrazy/3789.jpg';
import Obraz3788 from '../Images/obrazy/3788.jpg';
import Obraz3787 from '../Images/obrazy/3787.jpg';
import Obraz3786 from '../Images/obrazy/3786.jpg';
import Obraz3785 from '../Images/obrazy/3785.jpg';
import Obraz3784 from '../Images/obrazy/3784.jpg';
import Obraz3783 from '../Images/obrazy/3783.jpg';
import ObrazOpravaKostela from '../Images/oprava-kostela.jpg';
import ObrazHusite from '../Videos/husite.jpg';
import VideoHusite from '../Videos/husite.mp4';

import AudioBitva2 from '../Audio/bitva2.mp3';

import InteriorHistoricPhoto from '../Images/church-history/interier.jpg';
import MainAltarHistoricPhoto from '../Images/church-history/hlavni-oltar.jpg';
import KonventPhoto from '../Images/church-history/UB 1915 dominik.klaster budova konventu z ul.Mikolase Alse foto Ruzicka Vilib.jpg';


import ModernHistoryPhoto01 from '../Images/90-leta/1998-Graubner-0.jpg';
import ModernHistoryPhoto02 from '../Images/90-leta/1998-Graubner-3.jpg';
import ModernHistoryPhoto03 from '../Images/90-leta/cikrle5.jpg';
import ModernHistoryPhoto04 from '../Images/90-leta/cikrle6.jpg';
import ModernHistoryPhoto05 from '../Images/90-leta/Coppa-1998-1.jpg';
import ModernHistoryPhoto06 from '../Images/90-leta/duka6.jpg';
import ModernHistoryPhoto07 from '../Images/90-leta/hrdlička5.jpg';
import ModernHistoryPhoto08 from '../Images/90-leta/hrdlička7.jpg';
import ModernHistoryPhoto09 from '../Images/90-leta/KGantin-96-1.jpg';
import ModernHistoryPhoto10 from '../Images/90-leta/KGantin-96-27.jpg';
import ModernHistoryPhoto11 from '../Images/90-leta/KGantin-96-9.jpg';
import ModernHistoryPhoto12 from '../Images/90-leta/klášter-vánoce2000-5.jpg';
import ModernHistoryPhoto13 from '../Images/90-leta/Lidovci-pouť96-10.jpg';
import ModernHistoryPhoto14 from '../Images/90-leta/OHrdlička-96-2.jpg';
import ModernHistoryPhoto15 from '../Images/90-leta/Pouť-94-2.jpg';
import ModernHistoryPhoto16 from '../Images/90-leta/Pouť-95-MCoppa-3.jpg';
import ModernHistoryPhoto17 from '../Images/90-leta/Pouť-95-MCoppa-8.jpg';
import ModernHistoryPhoto18 from '../Images/90-leta/Pouť-95-MCoppa-9.jpg';
import ModernHistoryPhoto19 from '../Images/90-leta/Pouť-97-korunovace0.jpg';
import ModernHistoryPhoto20 from '../Images/90-leta/Pouť-97-korunovace1b.jpg';
import ModernHistoryPhoto21 from '../Images/90-leta/zvony1.jpg';
import ModernHistoryPhoto22 from '../Images/90-leta/zvony3.jpg';

import ModernHistoryPhoto01Small from '../Images/90-leta/small/1998-Graubner-0.jpg';
import ModernHistoryPhoto02Small from '../Images/90-leta/small/1998-Graubner-3.jpg';
import ModernHistoryPhoto04Small from '../Images/90-leta/small/cikrle6.jpg';
import ModernHistoryPhoto03Small from '../Images/90-leta/small/cikrle5.jpg';
import ModernHistoryPhoto05Small from '../Images/90-leta/small/Coppa-1998-1.jpg';
import ModernHistoryPhoto06Small from '../Images/90-leta/small/duka6.jpg';
import ModernHistoryPhoto07Small from '../Images/90-leta/small/hrdlička5.jpg';
import ModernHistoryPhoto08Small from '../Images/90-leta/small/hrdlička7.jpg';
import ModernHistoryPhoto09Small from '../Images/90-leta/small/KGantin-96-1.jpg';
import ModernHistoryPhoto10Small from '../Images/90-leta/small/KGantin-96-27.jpg';
import ModernHistoryPhoto11Small from '../Images/90-leta/small/KGantin-96-9.jpg';
import ModernHistoryPhoto12Small from '../Images/90-leta/small/klášter-vánoce2000-5.jpg';
import ModernHistoryPhoto13Small from '../Images/90-leta/small/Lidovci-pouť96-10.jpg';
import ModernHistoryPhoto14Small from '../Images/90-leta/small/OHrdlička-96-2.jpg';
import ModernHistoryPhoto15Small from '../Images/90-leta/small/Pouť-94-2.jpg';
import ModernHistoryPhoto16Small from '../Images/90-leta/small/Pouť-95-MCoppa-3.jpg';
import ModernHistoryPhoto17Small from '../Images/90-leta/small/Pouť-95-MCoppa-8.jpg';
import ModernHistoryPhoto18Small from '../Images/90-leta/small/Pouť-95-MCoppa-9.jpg';
import ModernHistoryPhoto19Small from '../Images/90-leta/small/Pouť-97-korunovace0.jpg';
import ModernHistoryPhoto20Small from '../Images/90-leta/small/Pouť-97-korunovace1b.jpg';
import ModernHistoryPhoto21Small from '../Images/90-leta/small/zvony1.jpg';
import ModernHistoryPhoto22Small from '../Images/90-leta/small/zvony3.jpg';

class Home extends Component {
    render() {
        return (
            <main id="main">
                <div className="main-content">
                    <div className="timeline-container">
                        <div className="">
                            <div className="item">
                                <div id="timeline">
                                    <section className="year">
                                        <h1 className="text-center" style={{paddingBottom: "50px"}}>
                                            Historie
                                        </h1>
                                        <section>
                                            <h4>První zmínky – pověst o růžovém keři</h4>
                                            <ul>
                                                <li>
                                                    <ResponsiveImage img={Obraz3798} title="Pověst o růžovém keři">
                                                        Podle pověsti r. 1019 nedaleko dnešního Uherského Brodu žili lidé vznešeného rodu, kteří toužili po následníkovi. (Dle pověsti se jednalo o pány z Volenova) Prosili tedy Pannu Marii a Pán Bůh jejich úpěnlivou prosbu vyslyšel. Manželé byli velmi šťastni, a proto přemýšleli, jak by se za tento dar Bohorodičce, která se za ně přimlouvala, jak by se jí odvděčili a ji uctili. Jednou v noci se paní ve snu zjevila Panny Maria a pověděla, aby hned ráno do nejbližšího lesa vyšla a na místě, kde nalezne rozkvetlý růžový keř, dala vystavět kapličku. Ráno radostně muži líčila co se jí stalo a vydala se s malou družinou hledat tento keř.
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3797} title="Pověst o růžovém keři – nalezení keře">
                                                        Byl právě leden a všude bylo plno sněhu, dlouho hledala paní v blízkých kopcích a když již byla unavená a o svém snu začala pochybovat, klesla na kolena a modlila se: „Ach, blahoslavená Panno, kde jest to místo, které jsi mně ve snách prstem ukázala, kde jest ten keř čerstvý, zelený a růže na něm vonné kvetoucí, o kterých jsi mně oznamovala? Nechtěj zdržovati služebnice tvej a ukaž to, co jsi přislíbila, abych já a manžel můj ke cti a chvále tvej učiniti mohli, co jsi sama poručila.“ V tom se nahoru podívala a zahlédla rozkvetlý keř.
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3796} title="Pověst o růžovém keři – návrat domů, předání růží manželovi">
                                                        Aby toto místo označila a znovu nalezla, dala pokácet stromy v okolí a natrhala trochu růží a vrátila se k manželovi domů. Manžel byl tímto zázrakem potěšen a ihned na jaro dal vystavit dřevěnou kapličku, která ohradila rozkvetlý keř.
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3795} title="Poutě k zázračnému keři">
                                                        Podle tradice se má jednat o místo, kde dnes v klášteře dominikánů stojí oltář Panny Marie růžencové. Tento keř kvetl celoročně a sjížděli se k němu poutníci z širokého okolí (Slezsko, Polsko, Uhry).
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3794} title="Místo dřevěného kostelíku nechal postavit král Ondřej kamenný.">
                                                        Když se zvěst o tomto zázračném místě donesla k uherskému králi Ondřejovi I., chtěl také toto místo navštívit. Když se tak stalo, nechal na místě vystavit na svůj náklad kostel kamenný (r. 1049). Lidé se pravidelně sjížděli k pobožnostem a stavili si domky poblíž, až Ondřej I. Nechal v r. 1049 vyměřit geometry město.
                                                    </ResponsiveImage>
                                                </li>
                                                <li>
                                                    <Link to={`/povest-o-zalozeni-mesta-povest-o-ruzovem-keri`} target="_blank">
                                                        O pověsti se rozepsal v roce 1724 Václav František Letocha.
                                                    </Link>
                                                </li>
                                                <li><br /><br /><br /><strong>Konec 13. a začátek 14. století – vybudování kláštera</strong></li>
                                                <li>
                                                    <ResponsiveImage img={Obraz3793} title="Darování posvátného obrazu Panny Marie brodskému klášteru.">
                                                        Podle tradice daroval uherský panovník obraz chrámu, který sám nechal postavit, pro povzbuzení větší zbožnosti lidu. Podle pověsti by se mělo jednat o obraz namalovaný sv. Lukášem, který nedávno Ondřej I. dostal jako dar od císaře konstantinopolského.
                                                        <br />
                                                        Jinde se hovoří o uherském králi Ondřejovi II, který jej měl přivézt z křižácké výpravy z Cařihradu.
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3792} title="První převor Melchior zakládá růžencové bratrstvo.">
                                                        Kvůli touze po bratrském životě prvotních křesťanů, byla zakládána laická řádová sdružení (předchůdci dnešních třetích řádů) které umožňovaly i křesťanům, jež byli jinak vázáni světskými starostmi (i svou obživu, nebo o rodinu) přiblížit se ideálu bratrské lásky prožívané v komunitě. Růžencová bratrstva se vyznačovala přísnější organizací po vzoru církevních řeholí, ale požívala také řady milostí ve formě odpustků. Věnovali se především společné modlitbě jak v uzavřeném kruhu, tak na veřejných bohoslužbách, organizovali procesí a poutě s vlastními praporci nebo pořádali pohřby zemřelým spolubratrům. Příslušníci těchto bratrstev pocházeli z různých společenských vrstev a často se zde uplatňovala výpomoc majetnějších členů bratrstva sociálně slabším.
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                    </section>
                                    <section className="year animatedParent animateOnce">
                                        <h3>15. století</h3>
                                        <section className="animated fadeInUpShort">
                                            <h4>1354–1419</h4>
                                            <ul>
                                                <li>Na věžích prý byly v letech 1354–1419 umístěny hodiny a dva zvony, zničené za husitských válek.</li>
                                            </ul>
                                        </section>
                                        <section className="">
                                            <h4>1421</h4>
                                            <ul>
                                                <li>Města se zmocnili husité, kteří celý klášterní komplex vypálili
                                                    <div className="video-container" style={{ "margin": "30px 0" }}>
                                                        <video width="700" controls poster={ObrazHusite}>
                                                            <source src={VideoHusite} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    <div className="description">
                                                        Husitské války poznamenaly život města i kláštera, na přelomu r. 1427 a 1428 spojená husitská vojska směřující na Pováží obléhala město, které se, i když bylo dobře opevněno, zřejmě bez vážnějšího odporu vzdalo. bratři dominikáni byli z části vyvražděni, z části rozehnáni, jiní potají zachráněni, jak hlásá svědectví poskytnuté městem: Od těch časů Žizkova husitského pronásledování, když byl klášter a kostel stráven ohněm, mniši zčásti kvůli pronásledování, zčásti kvůli jeho poboření, nemohli konvent obývat, a tak se tajně nějaký čas schovávali u jakési statečné brodské měštky řčené Bartoška a z její štědrosti podporováni, přece jen, když se do široka daleka husitská víra rozšířila, nadobro kraj opustili. Útočiště nalezli v sousedním Polsku. Po válkách husitských se část řeholníků do města vrátila, nicméně po r. 1520 se začala i v našich zemích šířit luteránská reformace, jež našla zvlášť na Moravě množství příznivců. V té době podruhé klášter zanikl (r. 1545).
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                        <section className="animated fadeInUpShort delay-500">
                                            <h4>kolem roku 1435</h4>
                                            <ul>
                                                <li>Do zničeného kláštera se vrátili teprve kolem roku 1435, a přestože byl jejich majetek z velké části rozvrácen, 
                                                pustili se do opravy kostela.</li>
                                            </ul>
                                        </section>
                                    </section>
                                    <section className="year animatedParent animateOnce">
                                        <h3>16. století</h3>
                                        <section className="animated fadeInUpShort">
                                            <h4>1506</h4>
                                            <ul>
                                                <li>Město Uherský Brod definitivně přešlo do vlastnictví pánů z Kunovic a stalo se městem poddanským</li>
                                            </ul>
                                        </section>
                                        <section className="animated fadeInUpShort">
                                            <h4>1546</h4>
                                            <ul>
                                                <li>Větší část dominikánů po r. 1546 opět odchází a klášter zaniká a poté se stává protestantským</li>
                                            </ul>
                                        </section>
                                        <section className="animated fadeInUpShort">
                                            <h4>1562</h4>
                                            <ul>
                                                <li>Pohřbena manželka Jana z Kunovic Magdalena</li>
                                            </ul>
                                        </section>
                                    </section>
                                    <section className="year">
                                        <h3>17. století</h3>
                                        <section>
                                            <h4>1605</h4>
                                            <ul>
                                                <li>Uherský magnát Štěpán Bočkaj napadl se svým vojskem město
                                                    <div className="audio-container">
                                                        <audio controls>
                                                            <source src={AudioBitva2} type="audio/mpeg" />
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1625</h4>
                                            <ul>
                                                <li>
                                                    Počátky obnoveného kláštera však byly takéznačně komplikované, 
                                                    poněvadž i klášterní zboží bylo rozvráceno a k tomu klášterní komplex před příchodem řeholníků, 
                                                    při velkém požáru roku 1625 vyhořel.

                                                    <br />
                                                    Podle pamětí Jana Aquina to bylo 29.3. 1626: „in aedibus Iudaci cujusdam ignis exortus, unde Claustrum B. 
                                                    Virginis Mariae, Turris Campanae Scholaque Parochiae igne absumpta nec non 54 incolarum aedes“. <i>(SOkA Uh. Hradiště, UB sbírky, Ol 21.)</i>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1630</h4>
                                            <ul>
                                                <li>r. 1630 se dominikáni vrátili a znovu započali s budováním kostela<br />
                                                    <ResponsiveImage img={ObrazOpravaKostela} title="Obnova kláštera po bílé hoře">
                                                        Po vítězství císaře Ferdinanda II. byl stav v kraji žalostný. Od roku 1625 bylo město katolické, ale valná většina těch co zůstali byla katolíky pouze na oko. Vesnice byly téměř liduprázdné, nekatoličtí kněží byly nuceni odejít z far a až do roku 1630 byly vesnice na uherskobrodském panství kounicovském úplně bez kněží. V té době znovu přicházejí do Uherského Brodu dominikáni, klášter nalezli v ubohém stavu, neboť nebyl řadu let opravován a v době válek hodně utrpěl. Dominikáni se postupně ujímali farností v okolí (znázorněny kostelíčky, které nesou bratři dominikáni (můžeme rozeznat názvy farností Nivnice, Hradčovice, Bánov...) Prvním převorem byl Abraham Kalewski - stěžoval si, že je zde jen 10 katolíků). 
                                                        Byli pověřeni rekatolizací obyvatelstva, která byla na kounicovském panství dokončena do konce 18. století.
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1643</h4>
                                            <ul>
                                                <li>kostel vypálili Švédové </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1651</h4>
                                            <ul>
                                                <li>
                                                    Na věž umístěn zvon zasvěcený Panně Marii Růžencové. Byl odlit za převora Theofila z Ostropole 23.6. roku 1651 v 9 hodin dopoledne. 
                                                    Jeho autorem je olomoucký zvonař Zwerger. Tento zvon je již poněkud větších rozměrů, váží asi 22 centů, 
                                                    což je kolem 1350 kilogramů a je tak největším zvonem města. Materiál na zvon z velké části poskytl jeho rovněž velký předchůdce Maria 
                                                    zničený nedlouho po svém zhotovení za švédského vpádu v roce 1643.  V roce 1702 přibyly k tomuto velkému zvonu ještě dva menší: svatého Jana 
                                                    Nepomuckého a patronky města svaté Justiny. Všechny tři zvony ladily ve stupnici D dur.   
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1656-1673</h4>
                                            <ul>
                                                <li>Radikální přestavba klášterního komplexu probíhala v letech 1656–1673 a tuto skutečnost připomíná rodový erb Dietrichštejnů umístěný nad raně barokním portálem hlavního vstupu do kostela
                                                <br />
                                                <ResponsiveImage img={Obraz3789} title="Radikální přestavba">
                                                </ResponsiveImage>
                                                </li>
                                                <li>K obnovenému kostelu byly připojeny i dvě charakteristické věže, v nichž hraběnka Eleonora Kounicová (z rodu Ditrichstejnů) dala na jižní straně umístit zvon ulitý v r.1651 olomouckým zvonařem Jiřím Zwengerem a zasvěcený Panně Marii Růžencové.</li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>14. července 1683</h4>
                                            <ul>
                                                <li>
                                                    <ResponsiveImage img={Obraz3787} title="Uherský Brod za vpádu tökölyovských vojsk 14. července 1683">
                                                        Toto období bylo pro Broďany velmi těžké, stačí nahlédnout do kronik. 1663 vpád turecko-tatarských nájezdníků – pro Broďany se jednalo zejména o náklady na ubytování vojska a jeho vydržování, 
                                                        které způsobilo obyvatelům nemalou zátěž. Okolní obce jako Těšov, Újezdec, Suchá Loza, Bánov, Kortná, Biskupice, Záhorovice, Komňa a Bojkovice byly zcela vypáleny. 
                                                        Bylo odvlečeno množství zajatců, většina hospodářských zvířat a zničena celoroční úroda obilí. V následujících letech Brod poškodily další pohromy.
                                                        R. 1667 požár, který zničil radnici, českobratrský sbor a dalších 81 domů, jen dva roky poté v r. 1669 vyhořela znovu velká část města včetně farního kostela a r. 1672 přišla povodeň. 
                                                        Aby toho nebylo málo r. 1680 přichází morová epidemie, která skoro půl roku sužuje město a připraví o život stovky jeho obyvatel. A do toho všeho 14. července 1683 pronikají z nedalekých Uher vojska
                                                        včele s Imrichem Tökölym. Ten byl spojencem tureckých vojsk obléhajících Vídeň, hlavní město habsburské monarchie, oficiálně zrušil příměří s císařem Leopoldem I. 
                                                        A část jeho armády podnikala celé léto zničující vpády na území Moravy. Nejhůře byl postižen právě Uherský Brod, v té době bez vojenské ochrany, nedostatečně vyzbrojen a se značně poškozeným opevněním 
                                                        z dřívějších let. Vojska nejdříve zpustošila předměstí Uherského Brodu a nedaleký Lipový dvůr, hřebčín v roce 1653 založený Lvem Vilémem z Kounic (na místě dnešního zimního stadionu). 
                                                        Po několikerém pokusu o prolomení jižní Nivnické brány se vzbouřenci obrátili k severnímu opevnění, kde v místech Zámecké brány pronikli do města, vyloupili jej a zapálili. 
                                                        Úplně zničili dominikánský klášter, farní kostel sv. Jana Křtitele a radnici, kde krátce předtím v městské kanceláři vzbouřenci sťali purkmistra Václava Manšveta. 
                                                        Při odchodu vojska město na několika místech zapálila, takže ohni padla za obě téměř třetina města od Panského domu až k Nivnické bráně. 
                                                        A zemřelo celkem 168 křesťanů a 93 židů. Nejbolestnější ovšem bylo, že mezi obětmi byli významní měšťané kteří mohli být Uherskému Brodu přínosem v období jeho následné obnovy. 
                                                        Celková materiální škoda byla odhadnuta na 136 416 zl. 33 kr.
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>Konec 17. stolení</h4>
                                            <ul>
                                                <li>
                                                    <ResponsiveImage img={Obraz3788} title="Přenesení ostatků sv. Justýny">
                                                        Dominik Ondřej z Kounic, syn Eleonory, císařský diplomat získal v Římě tělo svaté Justiny (o koho se jedná, není známo, tehdy se mučedníci „těžili“ v katakombách, jsou jich tisíce v kostelech po celé Evropě). Na obraze je ještě stará radnice. Poměrně věrně zachycuje podobu města na sklonku 17. století.
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                    </section>
                                    <section className="year">
                                        <h3>18. století</h3>
                                        <section>
                                            <h4>Začátek 18. století</h4>
                                            <ul>
                                                <li>Starý zvon Panny Marie o váze 2.200 kg byl za převora Adriána Kouřimského (1699–1702) doplněn dvěma menšími zvony sv. Jana Nepomuckého (150 kg) a sv. Justiny (220 kg), které vytvořil Vincenc Jakub Deromet z Vídně</li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1704</h4>
                                            <ul>
                                                <li>
                                                    <ResponsiveImage img={Obraz3786} title="Snaha se zmocnit města v roce 1704">
                                                        <p>
                                                            Město se tou dobou již zotavilo z nejhorších ran, úroda byla nadměrná, obchod rozproudily nově vzniklé obilné trhy a i řemesla znovu nabývají svého věhlasu v ceších. Obyvatelé se vymanili ze závislosti na panských hejtmanech a vrchnosti (zrušení člověčenství 12. dubna 1700), což dopomohlo k rozproudění hospodářství. Tento rozkvět neohrozily ani vpády vojska Františka II. Rákocziho mezi lety 1704 - 1708.
                                                        </p>
                                                        <p>
                                                            Nejvíce byl Brod ohrožen ve dnech 27. a 28. března 1704. Tehdy kuruci přitáhli k městu pod vedením Vavřince Pekryho a zaútočili na jeho opevnění mezi Těšovskou branou a Bílou baštou na hradbách zuřily prudké boje a dominikáni se modlili v čele s převorem Horetzkým před svatostánkem litanii loretánskou a ke všem svatým. Růžencový kazatel P. Prokop Rogl zatím shromáždil ženy a děti a uspořádal průvod městem za hlasitého modlení se růžence za vítězství. Poté vystoupil společně se ženami na hradby, kde se chápali dřeva a kamení, které házeli na nepřítele. Nepřítel se ještě pokusil o útok na Nivnickou bránu, i zde však byl odražen, a tak se po marném pokusu dobýt město obrátili k ústupu do Uher.
                                                        </p>
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1742</h4>
                                            <ul>
                                                <li>
                                                    <ResponsiveImage img={Obraz3785} title="Pruský útok  1742">
                                                        <p>
                                                            Nezvyklý pohled na město ve směru od Havřic. Ukazuje pruská vojska. Ta se dostala na Moravu v rámci boje o habsburské dědictví. Po smrti císaře Karla VI., který neměl mužské dědice, se sousední státy (Sasko, Bavorsko, Prusko) pokusily ovládnout rakouské soustátí, ačkoliv se před tím zavázaly respektovat tzv. Pragmatickou sankci. V roce 1742 proniklo ze Slezska na jihovýchod Moravy pruské vojsko pod vedením Dětřicha z Anhaltu a obsadila mj. Hradiště. Brodská městská rada se rozhodla bránit, zatarasila kromě nivnické všechny brány.
                                                        </p>
                                                        <p>
                                                            Prusů bylo ovšem 10 tisíc a usadili se v Havřicích a blízkém okolí. Vůči této převaze se rozhodla rada města kapitulovat a vpustit Prusy do města. Zachránila tak mnoho životů, ale následné rabování připravilo město i obyvatele o značný majetek.
                                                        </p>
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3784} title="Svěcení klášterního kostela olomouckým biskupem Jakubem Arnoštem z Lichtenštejna v roce 1742">
                                                        <p>Obraz zachycuje svěcení klášterního kostela olomouckým biskupem Jakubem Arnoštem z Lichtenštejna v roce 1742. Když přijel, zvonily všechny zvony, uvítali jej nejenom hudebníci, ale i vojáci. Do klášterního kostela uvedl biskupa převor a přivedl jej k trůnu či oratoři před hlavním oltářem, poté se konala modlitba loretánských litanií za doprovodu hudby s výstavem a požehnáním a okouřením nejsvětější svátosti.
                                                            Po modlitbách, požehnání a uctívání nejsvětější svátosti a Panny Marie udělil do pontifikálií oděný biskup svátost biřmování 555 dospělým i dětem.
                                                        </p>
                                                        <p>
                                                            Poté se odebral do konventu, kde povečeřel. Po nešporách, které skončily asi v sedm hodin, se odebral otec hebdomadář (mnich nebo kanovník, který řídí průběh denní modlitby církve) oděný do superpelice (rochety) a štoly se dvěma akolyty ze sakristie k ceremonáři pro svaté ostatky, které měly být uloženy ve třech oltářích, ve velkém, růžencovém a oltáři svatého Kříže. Po přinešení byly uctivě uloženy v sakristii a proběhla noční vigilie. Následujícího dne 28. srpna byli bratři probuzeni ve třičtvrtě na čtyři a krátce na to proběhly první modlitby. V pět hodin zapálil jáhen v kostele dvanáct svíc u dvanácti křížů v kostele. Když se klerus spolu s biskupem shromáždil před dveřmi kostela, biskup třikrát udeřil biskupskou holí na dveře a třikrát po trojím obejítí kostela zazpíval: otevřte brány vaše knížata. Z kostela se ozvalo: Kdo je tento král slávy? Po otevření dveří biskup s klérem a několika váženými laiky při vstupu pronesl: Král tohoto Pána (Rex huic Domini). Jáhen mu odpověděl: Ve vašem vstupu (in introitu vestro). Zavřený kostel pak prošel biskup s klérem křížem a pokropil za modliteb oltáře.
                                                        </p>
                                                        <p>
                                                            Poté byl postaven do prostřed kostela na zem dřevěný svatoondřejský kříž, jenž byl popsán řeckými a latinskými literami v červené a černé barvě. Na kříži byl rozptýlen popel, do kterého psal biskup řecká a latinská písmena. Poté byly vyzvednuty ostatky a obřadně přeneseny do kostela a položeny na oltářní desku oltáře svaté Justiny. V procesí šli čtyři nosiči kadidla (thuriferarii), poté biskup s klérem a lidem s ostatky, kolem kterých neslo šest senátorů pochodně. Přišli z konventu do kostela, kde biskup vystoupil do připravené oratoře a následovaly litanie ke všem svatým, přičemž jména svatých mučedníků, jimž patřily ostatky, byla podvakrát vyslovena. Potom byly vysvěceny oltáře, první růžencový, potom Svatého Kříže a nakonec velký. U každého oltáře zůstal kaditelník až do doby, dokud biskup neposvětil a nepomazal namalované kříže.
                                                        </p>
                                                        <p>
                                                            Po svěcení slavil biskup mši u nově posvěceného oltáře Nejsvětějšího růžence. Poté při slyšení dvou čtených mší vykonal své hodinky a nakonec provedl biřmování přibližně 700 lidí. Při tomto biřmování vykonal v dolním ambitu za doprovodu hudby zpívanou mši napajedelský farář Pavel Kolařík. Tato ranní slavnost trvala až do půldruhé hodiny odpolední, vysvěcení kostela a oltářů do hodiny osmé, biřmování a mše do půldruhé. Biskup poté posnídal sám s převorem, ostatní hosté v refektáři. Ve čtyři hodiny odpoledne sestoupil biskup s klérem opět do kostela, kde provedl biřmování dalších přibližně šedesáti osob. Poté převor, jako předešlého dne, pronesl litanie s požehnáním, po jejichž skončení vyprovodil biskupa z kostela a spolu s celým konventem mu poděkoval za posvěcení kostela.
                                                        </p>
                                                    </ResponsiveImage>
                                                    <ResponsiveImage img={Obraz3783} title="Nedatovaná slavnost růžencového bratrstva.">
                                                    </ResponsiveImage>
                                                </li>
                                            </ul>
                                        </section>
                                    </section>
                                    <section className="year">
                                        <h3>20. století</h3>
                                        <section>
                                            <h4>20. léta 20. století</h4>
                                            <ResponsiveImage img={KonventPhoto} title="Dominikánský klášter – budova konventu z ul. Mikoláše Alše, foto Růžička Vilibald, z archivu Muzea J. A. Komenského v Uherském Brodě">
                                            </ResponsiveImage>
                                            <ResponsiveImage img={InteriorHistoricPhoto} title="autor neznámý, z archivu Muzea J. A. Komenského v Uherském Brodě">
                                            </ResponsiveImage>
                                            <ResponsiveImage img={MainAltarHistoricPhoto} title="autor neznámý, z archivu Muzea J. A. Komenského v Uherském Brodě">
                                            </ResponsiveImage>
                                        </section>
                                        <section>
                                            <h4>50. léta</h4>
                                            <ul>
                                                <li>
                                                    Poslední násilí se ve zdech tohoto kláštera a kostela odehrálo v 50. letech minulého století, kdy došlo po nařízení komunistické vlády ke zrušení mužských řádů. 
                                                    <br />
                                                    <Link to={`/vyhnani-dominikanu-z-uherskeho-brodu-v-roce-1950`}>
                                                        Dominikáni tedy museli klášter opět opustit. Vrátit se mohli až po událostech v roce 1989.
                                                    </Link>
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>1989</h4>
                                            <ul>
                                                <li>
                                                    Obnova kláštera; zamalovány obrazy na klenbě. Ponechán pouze jeden nad hlavním oltářem s výjevem korunování Panny Marie.
                                                </li>
                                            </ul>
                                        </section>
                                        <section>
                                            <h4>90. léta</h4>
                                            <div className="history-photo-gallery">
                                                <img src={ModernHistoryPhoto15Small} alt="1994 Pouť" data-caption="1994 Pouť" data-fancybox="gallery" href={ModernHistoryPhoto15} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto16Small} alt="1995 Coppa" data-caption="1995 Coppa" data-fancybox="gallery" href={ModernHistoryPhoto16} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto17Small} alt="1995 Coppa" data-caption="1995 Coppa" data-fancybox="gallery" href={ModernHistoryPhoto17} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto18Small} alt="1995 Coppa" data-caption="1995 Coppa" data-fancybox="gallery" href={ModernHistoryPhoto18} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto09Small} alt="1996 Gantin" data-caption="1996 Gantin" data-fancybox="gallery" href={ModernHistoryPhoto09} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto10Small} alt="1996 Gantin" data-caption="1996 Gantin" data-fancybox="gallery" href={ModernHistoryPhoto10} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto11Small} alt="1996 Gantin" data-caption="1996 Gantin" data-fancybox="gallery" href={ModernHistoryPhoto11} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto13Small} alt="1996 Lidovecká pouť" data-caption="1996 Lidovecká pouť" data-fancybox="gallery" href={ModernHistoryPhoto13} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto14Small} alt="1996 Hrdlička" data-caption="1996 Hrdlička" data-fancybox="gallery" href={ModernHistoryPhoto14} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto19Small} alt="1997 Jan Graubner - korunovace" data-caption="1997 Jan Graubner - korunovace" data-fancybox="gallery" href={ModernHistoryPhoto19} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto20Small} alt="1997 Jan Graubner - korunovace" data-caption="1997 Jan Graubner - korunovace" data-fancybox="gallery" href={ModernHistoryPhoto20} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto01Small} alt="1998 Jan Graubner" data-caption="1998 Jan Graubner" data-fancybox="gallery" href={ModernHistoryPhoto01} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto02Small} alt="1998 Jan Graubner" data-caption="1998 Jan Graubner" data-fancybox="gallery" href={ModernHistoryPhoto02} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto03Small} alt="Vojtěch Cirkle" data-caption="Vojtěch Cirkle" data-fancybox="gallery" href={ModernHistoryPhoto03} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto04Small} alt="Vojtěch Cirkle" data-caption="Vojtěch Cirkle" data-fancybox="gallery" href={ModernHistoryPhoto04} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto05Small} alt="1998 Coppa" data-caption="1998 Coppa" data-fancybox="gallery" href={ModernHistoryPhoto05} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto06Small} alt="Dominik Duka" data-caption="Duka" data-fancybox="gallery" href={ModernHistoryPhoto06} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto07Small} alt="Mons. Josef Hrdlička" data-caption="Mons. Josef Hrdlička" data-fancybox="gallery" href={ModernHistoryPhoto07} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto08Small} alt="Mons. Josef Hrdlička" data-caption="Mons. Josef Hrdlička" data-fancybox="gallery" href={ModernHistoryPhoto08} data-fancybox-group="all" />
                                                <img src={ModernHistoryPhoto12Small} alt="2000 Vánoce" data-caption="" data-fancybox="gallery" href={ModernHistoryPhoto12} data-fancybox-group="all" />
                                            </div>
                                            Foto: RNDr. Stanislav Novák
                                            
                                        </section>
                                        <section>
                                            <h4>1997</h4>
                                            <ul>
                                                <li>
                                                    <div className="description">
                                                        Korunování obrazu Panny Marie v Uherském Brodě papežským diadémem. 
                                                        Obraz nese titul Královna posvátného růžence. 
                                                        Původní kovové korunky postrádají dataci. 24. 9. – posvěcení korunek bl. Janem Pavlem II. v Římě, 
                                                        5. 10. – slavná korunovace obrazu olomouckým arcibiskupem Mons. 
                                                        Janem Graubnerem při růžencové pouti v Uherském Brodě.
                                                        <div className="history-photo-gallery">
                                                            <img src={ModernHistoryPhoto21Small} alt="" data-fancybox="gallery" href={ModernHistoryPhoto21} data-fancybox-group="all" />
                                                            <img src={ModernHistoryPhoto22Small} alt="" data-fancybox="gallery" href={ModernHistoryPhoto22} data-fancybox-group="all" />
                                                        </div>
                                                        Foto: RNDr. Stanislav Novák
                                                        <div className="video-iframe-wrapper-container padding-top-50">
                                                            <div className="video-iframe-wrapper">
                                                                <iframe width="560" height="349" src="http://www.youtube.com/embed/teJi-rmfxV0?rel=0&hd=0" frameBorder="0" allowFullScreen></iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                    </section>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </main>
        );
    }
}

export default Home;