import Client from "../Client.js";
import { SortOrder } from 'kentico-cloud-delivery';

let photogalleryList = [];
let photogalleryListCapacity = 0;

let photogalleryDetails = {};

let changeListeners = [];

let notifyChange = () => {
  changeListeners.forEach((listener) => {
    listener();
  });
}

class PhotogalleryStore {

  // Actions

  providePhotogallery(photogallerySlug) {

    Client.items()
      .type('photogallery')
      .equalsFilter('elements.url', photogallerySlug)  
      .getObservable()
      .subscribe(response => {
        if (!response.isEmpty) {
          photogalleryDetails[photogallerySlug] = response.items[0];
          notifyChange();
        }
      })
  }

  providePhotogalleries(count) {
    if (count <= photogalleryListCapacity) {
      return;
    }

    photogalleryListCapacity = count;

    Client.items()
      .type('photogallery')     
      .orderParameter("elements.createdWhen", SortOrder.desc)
      .getObservable()
      .subscribe(response =>
        {
          photogalleryList = response.items;
          notifyChange();
        });
  }

  // Methods
  
  getPhotogallery(photogallerySlug) {
    return photogalleryDetails[photogallerySlug];
  }

  getPhotogalleries(count) {
    return photogalleryList.slice(0, count);
  }

  // Listeners

  addChangeListener(listener) {
    changeListeners.push(listener);
  }

  removeChangeListener(listener) {
    changeListeners = changeListeners.filter((element) => {
      return element !== listener;
    });
  }

}

export default new PhotogalleryStore();