import React, { Component } from 'react';
import BannsStore from '../Stores/Banns';
import { Link } from 'react-router-dom';
import { dateFormats } from '../Utilities/LanguageCodes'
import dateFormat from 'dateformat';

let bannsCount = 1000;

let getState = () => {
  return {
    bannsList: BannsStore.getBannsList(bannsCount)
  };
};


let formatDate = (value) => {
  return dateFormat(value, "dd. mm. yyyy");
};

let createdWhen = (date) => {
  if (date.value != null && date.value.length > 0 && date.value !== "<p><br></p>") {
    return (
        formatDate(date.value)
    );
  }
  
  return null;
}

class BannsList extends Component {

  constructor(props) {
    super(props);

    this.state = getState();
    this.onChange = this.onChange.bind(this);

    dateFormat.i18n = dateFormats[props.language] || dateFormats[0];
  }

  componentDidMount() {
    BannsStore.addChangeListener(this.onChange);
    BannsStore.provideBanns(bannsCount);
  }

  componentWillUnmount() {
    BannsStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getState());
  }

  render() {
    let counter = 0;

    let bannsList = this.state.bannsList.reduce((result, banns, index) => {
      let link = `/ohlasky/${banns.system.codename}`;

      result.push(
        <li key={counter++} className="banns-item">
            <Link to={link}>
              <span className="banns-main-title">{banns.perex.value}</span>
            </Link> 
            <span  className="banns-main-date">
              ({createdWhen(banns.timefrom)} – {createdWhen(banns.timeto)})
            </span>
        </li>
      );

      return result; 
    }, []);

    if (bannsList.count < 0) {
      return (
        <div className="container"><div className="page-spinner"></div></div>
      );
    }

    return (
        <ul className="banns-list-container">
          {bannsList}
        </ul>
    );
  }
}

export default BannsList;