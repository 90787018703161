import React, { Component } from 'react';
import FullPageText from '../Components/FullPageText';
import ResponsiveImage from '../Components/ResponsiveImage';

import TeaserImage from '../Images/pohled-do-cely2.jpg';
import Dominik from '../Images/dominik2.jpg';
import WellImage from '../Images/klaster/studna.jpg';
import MniskyImage from '../Images/mnisky/mnisky.JPG';
import Mnisky2Image from '../Images/mnisky/mnisky2.JPG';
import VisualisationImage from '../Images/mnisky/zrekonstruovany-klaster.JPG';
import HrubaStavba from '../Images/rekonstrukce/rekontrukce-hruba-stavba.jpg';

class MonasteryPage extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Přestavba
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                 Klášterní budova byla na konci 80. let minulého století v dezolátním
                                stavu. Začalo se s její opravou pro potřeby hudební školy
                                a po navrácení kláštera řádu dominikánů v roce 1989 také pro potřeby
                                menší komunity bratří. 
                                </p><p>
                                Klauzurní způsob života komunity
                                mnišek vyžaduje některé nezbytné stavební úpravy stávající budovy,
                                jako je zřízení kuchyně s refektářem, vybudování výtahu, pracoven
                                a hygienického zázemí či vyčlenění prostoru pro noviciát. Dále
                                je nutné rozšířit areál o kvadraturu a dům pro hosty. 
                                </p><p>
                                Úpravy se realizují podle důkladně zpracovaného projektu s důrazem
                                na hospodárnost, na použití trvanlivých materiálů a na řešení odpovídající
                                současným technologiím. Při využití všeho, co lze ze současného
                                zařízení rozumně použít, se přihlíží k minimalizaci budoucích
                                provozních nákladů.
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={HrubaStavba} width="100%" alt="cela" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <ResponsiveImage img={VisualisationImage} title="Vizualizace budovy po přestavbě" />
                <FullPageText showStar={false}>
                    Jsme kontemplativní komunita dominikánských mnišek.
                    Všem lidem <strong>nabízíme účast na</strong>:<br /><br />
                        <strong>
                            <ul>
                                <li>
                                    každodenním slavení mše svaté
                                </li>
                                <li>
                                    slavení dominikánské mnišské liturgie
                                </li>
                                <li>
                                    každodenní eucharistické adoraci
                                </li>
                                <li>
                                    modlitbě posvátného růžence
                                </li>
                            </ul>
                        </strong>
                </FullPageText>
              
                <div className="row photo-grid-container margin-top-100 ">
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={Mnisky2Image} data-fancybox-group="vestry">
                        <img src={Mnisky2Image} alt="Mnišky ve Znojmě" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={MniskyImage} data-fancybox-group="vestry">
                        <img src={MniskyImage} alt="Mnišky ve Znojmě" />
                    </div>
                    <div className="clearfix"></div>
                </div>

                <div className="op-container animatedParent animateOnce ">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="padding-top-50 margin-bottom-50 full-page-text">
                                Řád Kazatelů založil sv. Dominik Guzman na počátku 13. století. První klášter dominikánských mnišek byl založen v Prouille v jižní Francii r. 1206. Jejich posláním je dodnes modlitba a kontemplativní život, kterým podporují účinnost kázání bratří dominikánů a vyprošují Boží milost všem potřebným. Jejich skrytý každodenní společný život je protkán slavením liturgie, modlitbou, prací a studiem posvátných pravd.
                                <br /><br />
                                K obnovení mnišek Kazatelského řádu v naší zemi došlo po dlouhém úsilí až během Druhé světové války především zásluhou dominikánů P. Metoděje Habáně a P. Jiřího M. Veselého. Počátky obnovy byly velmi obtížné, komunita začínala ve veliké chudobě v nevyhovujících budovách. Ke kanonickému schválení došlo 30. dubna 1948 a už v září 1950 byly mnišky zásahem komunistického režimu vyvezeny do soustřeďovacích klášterů a nasazeny do továren. Během těchto let se komunita mnohokrát nuceně stěhovala. Po pádu totalitního režimu v roce 1989 se komunita usadila v klášteře bratří dominikánů ve Znojmě, protože nikdy neměla vlastní budovu. Nyní bratři nabídli mniškám klášter v Uherském Brodě, 
                                jehož prostory i areál lépe vyhovují způsobu života mnišek dominikánek
                            </div>
                        </div>
                    </div>
                </div>

                <FullPageText showStar={true}>
                    Na živobytí si vyděláváme vlastní prací. Zdobíme svíce, vyšíváme
                    křestní roušky, šijeme kališní prádlo a kněžské štoly, vyrábíme
                    růžence. Budeme vděčné, když nás zakoupením našich produktů
                    podpoříte
                </FullPageText>
                <FullPageText showStar={false}>
                    Na budování areálu kláštera můžete přispět: <br />
                    Raiffeisenbank a.s. účet: <strong>1014056624/5500</strong>
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={WellImage} alt="Studna v klášterní zahradě" title="Studna v klášterní zahradě" />
                </div>
                <FullPageText showStar={false}>
                    "Odhaluje hlubiny a skryté věci, poznává to, co je ve tmě, a světlo s&nbsp;ním bydlí." <br /><i>Daniel 2, 22</i>
                </FullPageText>
            </div>
        );
    }
}

export default MonasteryPage;