import React, { Component } from 'react';
import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import '../jquery.xgallerify';
import PhotogalleryStore from '../Stores/Photogallery';
import '../css/op-gallery.css';
//import RichTextElement from '../Components/RichTextElement';
import dateFormat from 'dateformat';
import { dateFormats } from '../Utilities/LanguageCodes'
import photogalleriesJSON from '../Data/newphotogallery.json';


let getState = (props) => {
  return {
    photogallery: photogalleriesJSON.items.filter(function (el) {
      return el.Url == props.match.params.photogallerySlug
    })[0]// PhotogalleryStore.getPhotogallery()
  };
};

let formatDate = (value) => {
  return dateFormat(value, "dd. mm. yyyy");
};

let counter = 0;

let photogalleryItem = (folderName, photoName) => {
  // var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  // var smallThumbnailURL = thumbnailURL + "?h=300&fm=" + (isChrome ? "webp" : "jpg");
  // let coef = 2;

  let imageUrl = `/Images/fotky/${folderName}/small/${photoName.replace('.JPG', '.jpg')}`;
  let bigImageURL = `/Images/fotky/${folderName}/${photoName}`;
  // if (window.location.hostname == "localhost")
  // {
  //   imageUrl = `/Images/fotky/${folderName}/small/${photoName.replace('.JPG', '.jpg')}`;
  //   bigImageURL = `/Images/fotky/${folderName}/${photoName}`;
  // }


  return (
    <div data-fancybox="gallery" data-fancybox-group="group" href={bigImageURL} className="photo" key={counter++}>
        <img src={imageUrl} alt={counter}/>
    </div>
  )
}

class Photogallery extends Component {

  constructor(props) {
    super(props);

    this.state = getState(props);
    this.onChange = this.onChange.bind(this);

    dateFormat.i18n = dateFormats[props.language] || dateFormats[0];
  }

  // componentDidMount() {
  //   PhotogalleryStore.addChangeListener(this.onChange);
  //   PhotogalleryStore.providePhotogallery(this.props.match.params.photogallerySlug);
  // }

  componentDidUpdate() {
    if ($('.photo-gallery .photo').length > 0 && false) {
        imagesLoaded('.photo-gallery', function() {
          var gallery = $('.photo-gallery').gallerify({
              margin:1,
              galleryMargin: 0,
              mode:{
                maxHeight: 300,
                breakPoints:[
                  {
                    minWidth: 2000,
                    columns: 8,
                  },
                  {
                    minWidth: 1170,
                    columns: 5,
                  },
                  {
                    minWidth: 970,
                    columns: 4,
                  },
                  {
                    minWidth: 750,
                    columns: 3,
                  },
                  {
                    maxWidth: 750,
                    columns: 2,
                  }
                ]
              },
              lastRow:'adjust'
          });	
        });

        $("[data-fancybox]").fancybox({
          hash : false
        });
      
    }
  }

  // componentWillUnmount() {
  //   PhotogalleryStore.removeChangeListener(this.onChange);
  // }

  onChange() {
    this.setState(getState(this.props));
  }

  render() {
    let photogallery = this.state.photogallery;
    if (!photogallery) {
      return (
        <div className="photo-gallery"></div>
      );
    }

    let description = (text) => {
      if (text.length > 0 && text !== "<p><br></p>") {
        return (
          <div className="photogallery-description">
            {/* <RichTextElement className="main-description-detail" element={text} /> */}
            {text}
          </div>  
        );
      }
      
      return null;
    }

    let formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
  }

    let createdWhen = (date) => {
      if (date != null && date.length > 0 && date !== "<p><br></p>") {
        return (
          <div className="photogallery-date">
            {"Datum: " + formatDate(new Date(date))}
          </div>
        );
      }
      
      return null;
    }
    let title = photogallery.AlbumName;
    let folderName = photogallery.FolderName;
    let mainPhotoName = photogallery.MainPhotoName;
   
    let photogalleryItems = photogallery.Photos
      .sort(function(a,b) {
          var x = a.toLowerCase();
          var y = b.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
      })  
      .map((photogalleryAsset) => {
        return (
          photogalleryItem(folderName, photogalleryAsset)
        );
      });
    return (
          <div className="main-content">
              <div className="op-container animatedParent animateOnce">
                  <div className="row">
                      <div className="col-xs-12 col-sm-12">
                          <h1 className="main-title">
                              {title}
                          </h1>
                          <div className="photogallery-author">
                              Autor: {photogallery.Author}
                          </div>
                          {createdWhen(photogallery.CreatedWhen)}
                          {description(photogallery.Description)}
                      </div>
                  </div>
              </div>
              <div className="photo-gallery">
                  {photogalleryItems}
              </div>
          </div>
    );
  }
}

export default Photogallery;