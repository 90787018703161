import Client from "../Client.js";
import { SortOrder } from 'kentico-cloud-delivery';
let bannsList = [];
let bannsListCapacity = 0;

let bannsDetails = {};

let changeListeners = [];

let notifyChange = () => {
  changeListeners.forEach((listener) => {
    listener();
  });
}

class BannsStore {

  // Actions

  provideBannsItem(codename) {
    Client.items()
      .type('banns')
      .equalsFilter('system.codename', codename)  
      .getObservable()
      .subscribe(response => {
        if (!response.isEmpty) {
          bannsDetails[codename] = response.items[0];
          notifyChange();
        }
      })
  }

  provideBanns(count) {
    if (count <= bannsListCapacity) {
      return;
    }

    bannsListCapacity = count;

    Client.items()
      .type('banns')
      .orderParameter('elements.TimeFrom', SortOrder.desc) 
      .getObservable()
      .subscribe(response =>
        {
          bannsList = response.items;
          notifyChange();
        });
  }

  // Methods
  
  getBannsItem(codename) {
    return bannsDetails[codename];
  }

  getBannsList(count) {
    return bannsList.slice(0, count);
  }

  // Listeners

  addChangeListener(listener) {
    changeListeners.push(listener);
  }

  removeChangeListener(listener) {
    changeListeners = changeListeners.filter((element) => {
      return element !== listener;
    });
  }

}

export default new BannsStore();