import React from 'react';
import Link from '../Components/LowerCaseUrlLink';
import LogoImage from '../Images/op.png';
import { translate } from 'react-translate'

//<Link to={`/${props.language}/Historie`}><span>Historie</span></Link>

// <li className="monastery">
// <Link to={`/klaster`}><span>Klášter</span></Link>
// </li>
const Menu = (props) => {
  return (
    <div className="menu">
        <span className="logo" href="./index.html">
            <Link to="/"><img src={LogoImage} alt="op" /></Link>
        </span>
        <ul>
            <li className="church">
                <Link to={`/kostel`}><span>Kostel</span></Link>
            </li>
            <li className="community">
                <Link to={`/komunita`}><span>Komunita</span></Link>
            </li>
            <li className="eshop">
                <Link to={`/produkty`}><span>Produkty</span></Link>
            </li>
            <li className="history">
                <Link to={`/historie`}><span>Historie</span></Link>
            </li>
            {/* <li className="bells">
                <Link to={`/zvony`}><span>Zvony</span></Link>
            </li> */}
            <li className="pilgrimage">
                <Link to={`/ruzencova-pout`}><span>Růžencová pouť</span></Link>
            </li>
            <li className="photogallery">
                <Link to={`/fotogalerie`}><span>Fotogalerie</span></Link>
            </li>
            <li className="microphone">
                <Link to={`/ohlasky`}><span>Ohlášky</span></Link>
            </li>
            {/* <li className="reconstruction">
                <Link to={`/prestavba`}><span>Přestavba</span></Link>
            </li> */}
            <li className="contact">
                <Link to={`/kontakt`}><span>Kontakt</span></Link>
            </li>
        </ul>
    </div>
  );
}

export default translate("Menu")(Menu);
