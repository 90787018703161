import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import $ from 'jquery';
import MassInfo from '../Components/MassInfo';
import FullPageText from '../Components/FullPageText';

import AltarList from '../Components/AltarList';

import TeaserImage from '../Images/kostel-interier/socha.jpg'; //'../Images/kostel-interier/kriz.jpg';
import AllChurchImage from '../Images/kostel-interier/kostel-celek.jpg';
import AllChurchHighlightedImage from '../Images/kostel-interier/kostel-celek-highlighted.jpg';
import CeilingBehindAltarImage from '../Images/kostel-interier/strop-za-oltarem.jpg';
import Whole1VestryImage from '../Images/sakristie/celek1.jpg';
import Whole2VestryImage from '../Images/sakristie/celek2.jpg';
import EntryVestryImage from '../Images/sakristie/vstup.jpg';
import CeilingVestryImage from '../Images/sakristie/strop.jpg';
import CeilingAboveMainAlter from '../Images/kostel-interier/obraz-nad-hlavnim-oltarem.jpg';
import CeilingHistoricalPhoto from '../Images/church-history/interier-zoom.jpg';

import SmallBellPhotoBW from '../Images/kostel-interier/zvonekBW.jpg';
import MainAltarFromBehindPhoto from '../Images/kostel-interier/hlavni-oltar-zezadu.jpg';
import CenserPhoto from '../Images/kostel-interier/kadidelnice.jpg';
import CrossPhoto from '../Images/kostel-interier/procesni-kriz.jpg';

import mapster from '../jquery.imagemapster';

class ChurchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            alias: ""
        }
    }

    componentDidMount() {
        var self = this;
        var initMapster = function () {
            var options = {
                fillColor: 'ffffff',
                fillOpacity: 1,
                stroke: true,
                render_highlight: {
                    strokeColor: 'ffffff',
                    fillColor: '2aff00',
                    stroke: false,
                    altImage: AllChurchHighlightedImage
                },
                // render_select: {
                //     fillColor: 'ffffff',
                //     stroke: false,
                //     altImage: AllChurchHighlightedImage
                // },
                //boundList: $(".map-menu").find('li a'),
                mapKey: "data-key",
                listKey: 'data-key',
                isSelectable: false,
                onClick: function (e) {
                    self.setState({ redirect: true, alias: e.key });
                }
            }

            if ($('.map').length > 0) {
                if ($('.mapster_el').length == 0) {
                    $('.map').mapster(options);
                }
                else {
                    $('.map').mapster('rebind', options);
                }
            }
            $('.map').mapster('rebind', options);

          
        }

        initMapster();
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={`/kostel/${this.state.alias}`} />;
        }

        return (
            <div className="main-content">
                <div className="relative">
                    <h1 className="main-title absolute">
                        <span>Klášterní kostel</span>
                    </h1>
                    <div className="map-container">
                        <img src={AllChurchImage} alt="celková fotka kostela" className="map" useMap="#mymap" />
                        <map name="mymap">
                            <area data-key="oltar-ucencu-radu" alt="Oltář učenců dominikánského řádu" title="Oltář učenců dominikánského řádu" href="#" 
                                coords="0,1367,0,280,595,334,546,1366" shape="poly" />
                            <area data-key="oltar-zakladatelu-dominikanskeho-radu" alt="Oltář zakladatelů dominikánského řádu" title="Oltář zakladatelů dominikánského řádu" href="#" 
                                coords="562,1350,601,530,845,556,830,1347" shape="poly" />
                            <area data-key="oltar-panny-marie-bolestne" alt="Oltář Panny Marie Bolestné" title="Oltář Panny Marie Bolestné" href="#" 
                                coords="840,1257,853,910,1000,915,1033,1245" shape="poly" />
                            <area data-key="oltar-panny-marie-nanebevzate" alt="Oltář Panny Marie Nanebevzaté" title="Oltář Panny Marie Nanebevzaté" href="#" 
                                coords="1055,1317,1065,785,1252,672,1444,808,1463,1305" shape="poly" />
                            <area data-key="oltar-svate-anny" alt="Oltář svaté Anny" title="Oltář svaté Anny" href="#" 
                                coords="1474,1253,1472,987,1494,918,1599,913,1637,994,1662,1275,1475,1280" shape="poly" />
                            <area data-key="oltar-mucedniku-dominikanskeho-radu" alt="Oltář mučedníků dominikánského řádu" title="Oltář mučedníků dominikánského řádu" href="#" 
                                coords="1685,1301,1620,543,1881,520,1904,519,1980,1311" shape="poly" />
                            <area data-key="oltar-kazatelu" alt="Oltář kazatelů dominikánského řádu" title="Oltář kazatelů dominikánského řádu" href="#" 
                                coords="2006,1382,1905,311,2498,233,2499,1416" shape="poly" />
                        </map>
                    </div>
                </div>
                
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Trojlodní chrám
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                    ...je zasvěcen Panně Marii Nanebevzaté, jejíž sousoší zdobí hlavní oltář. Je to dílo&nbsp;
                                    <Link to={`/benedikt-teltschik`}>mistra Benedikta Telčíka</Link> z roku 1781. Kromě tohoto oltáře je v kostele ještě dalších 8 menších oltářů, které jsou tématicky řazeny.
                                    Trojlodnímu chrámu dodává na působivosti barokní výzdoba Josefa Antonína Winterhaldera.
                                </p>
                                <p>
                                    Poutnicky nejvýznamnější částí kostela je růžencový oltář, na kterém je umístěn krásný barokní obraz Černé madony v pozlaceném barokním rámu. Originál tohoto obrazu získal podle brodské legendy král Ondřej I. od byzantského císaře a věnoval jej tomuto kostelu.
                                </p>
                                <p>
                                    Sv. Janem Pavlem II. je udělena <Link to={`/jan-pavel-ii-na-vecnou-pamatku`} target="_blank">výsada odpustků pro poutníky, kteří navštíví dominikánský chrám 1. neděli v květnu.</Link> 
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={TeaserImage} width="500px" alt="setník Longin" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="margin-top-50 relative animatedParent animateOnce">
                    <img src={CeilingBehindAltarImage} alt="detail stropu" />
                    <div className="center-parent absolute">
                        <div className="center-content big animated fadeInLeftShort delay-250 white">
                            Kostel je orientovaný směrem na východ. Světlo prochází přes velká okna bez vitráží.
                        </div>
                    </div>
                </div>
                
                <MassInfo language={this.props.language} />

                <AltarList />

                <FullPageText showStar={false}>
                    Na stropě nad hlavním oltářem i po poslední opravě zůstal obraz s&nbsp;výjevem Korunovace Panny Marie.
                    Stropní výmalba však byla v&nbsp;historii <a data-fancybox="gallery" href={CeilingHistoricalPhoto} data-fancybox-group="ceiling">mnohem bohatší</a>.
                </FullPageText>

                <div className="full-width-image-container margin-bottom-30 margin-top-100">
                    <img src={CeilingAboveMainAlter} alt="Obraz na stropě v presbitáři" />
                </div>
                <FullPageText showStar={false}>
                    Prostor za hlavním oltářem je nezvykle velký. Je zde vchod do podzemní krypty s 18 rakvemi. Další krypty jsou uprostřed presbytáře a před bočními oltáři. 
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={SmallBellPhotoBW} alt="zvonek ohlašující začátek mše" />
                </div>
                <div className="row photo-grid-container">
                    <div className="col-xs-12 col-sm-6 col-md-4" data-fancybox="gallery" href={MainAltarFromBehindPhoto} data-fancybox-group="behind-altar">
                        <img src={MainAltarFromBehindPhoto} alt="sakristie" />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4" data-fancybox="gallery" href={CrossPhoto} data-fancybox-group="behind-altar">
                        <img src={CrossPhoto} alt="Procesní kříž" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4" data-fancybox="gallery" href={CenserPhoto} data-fancybox-group="behind-altar">
                        <img src={CenserPhoto} alt="sakristie" />
                    </div>
                </div>
        { 
            // <div className="margin-top-50 relative animatedParent animateOnce">
            //         <img src={SmallBellPhotoBW} alt="zvonek ohlašující začátek mše" />
            //         <div className="center-parent absolute">
            //             <div className="center-content big animated fadeInLeftShort delay-250 white">
            //                 Kostel je orientovaný směrem na západ. Světlo prochází přes velká okna bez vitráží.
            //             </div>
            //         </div>
            //     </div>
        }

                <FullPageText showStar={false}>
                    Za hlavním oltářem vede vchod do klášterní budovy a do sakristie, kterou zbudoval dominikán P. Jan Novatius v r. 1736. Vstup do původní sakristie byl z levé strany, naproti na druhé straně se nacházel vchod, dnes už zazděný, kterým přicházela světská vrchnost do kostela klášterní zahradou z panského domu
                </FullPageText>
                
                <div className="row photo-grid-container">
                    <div className="col-xs-12 col-sm-6" data-fancybox="gallery" href={EntryVestryImage} data-fancybox-group="vestry">
                        <img src={EntryVestryImage} alt="sakristie" />
                    </div>
                    <div className="col-xs-12 col-sm-6" data-fancybox="gallery" href={CeilingVestryImage} data-fancybox-group="vestry">
                        <img src={CeilingVestryImage} alt="sakristie" />
                    </div>
                    <div className="col-xs-12 col-sm-6" data-fancybox="gallery" href={Whole1VestryImage} data-fancybox-group="vestry">
                        <img src={Whole1VestryImage} alt="sakristie" />
                    </div>
                    <div className="col-xs-12 col-sm-6" data-fancybox="gallery" href={Whole2VestryImage} data-fancybox-group="vestry">
                        <img src={Whole2VestryImage} alt="sakristie" />
                    </div>
                    <div className="clearfix"></div>
                </div>
               

            </div>
        );
    }
}

export default ChurchPage;