import {Component} from 'react';
import { withRouter } from 'react-router';
import animateIt from './css3-animate-it.js';

class AnimateItWrapper extends Component {
    componentDidMount(prevProps) {
        animateIt.reloadData();
    }

    componentDidUpdate(prevProps) {
        animateIt.reloadData();
    }

    render() {
        return this.props.children;
    }
  }
  
  export default withRouter(AnimateItWrapper);
