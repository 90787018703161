import Client from "../Client.js";
//import { SortOrder } from 'kentico-cloud-delivery-typescript-sdk';
import { initLanguageCodeObject, defaultLanguage } from '../Utilities/LanguageCodes'

let altarList = initLanguageCodeObject();
let altarDetails = initLanguageCodeObject();

let changeListeners = [];

let notifyChange = () => {
  changeListeners.forEach((listener) => {
    listener();
  });
}
class AltarStore {

  // Actions

  provideAltar(altarSlug, language) {
    let query = Client.items()
      .type('altar')
      .equalsFilter('elements.urlpattern', altarSlug);
      //.elementsParameter(['title', 'teaser_image', 'post_date', 'body_copy', 'video_host', 'video_id', 'tweet_link', 'theme', 'display_options']);

    if (language) {
        query.languageParameter(language);
    }
    query.getObservable()
      .subscribe(response => {
        if (!response.isEmpty) {
          if (language) {
            altarDetails[language][altarSlug] = response.items[0];
          } else {
            altarDetails[defaultLanguage][altarSlug] = response.items[0];
          }
          notifyChange();
        }
      })
  }

  provideAltars(count, language) {
    let query = Client.items()
      .type('altar')
      //.orderParameter("elements.post_date", SortOrder.desc);

    // if (language) {
    //   query.languageParameter(language);
    // }

    query.getObservable()
      .subscribe(response => {
        if (language) {
            altarList[language] = response.items;
        } else {
            altarList[defaultLanguage] = response.items
        }
        notifyChange();
      });
  }

  // Methods
  getAltar(altarSlug, language) {
    if (language) {
      return altarDetails[language][altarSlug];
    } else {
      return altarDetails[defaultLanguage][altarSlug];
    }
  }

  getAltars(count, language) {
    if (language) {
      return altarList[language].slice(0, count);
    }
    else {
      return altarList[defaultLanguage].slice(0, count);
    }
  }

  // Listeners
  addChangeListener(listener) {
    changeListeners.push(listener);
  }

  removeChangeListener(listener) {
    changeListeners = changeListeners.filter((element) => {
      return element !== listener;
    });
  }

}

export default new AltarStore();