import React, { Component } from 'react';
import TextblockStore from "../Stores/Textblock";
import RichTextElement from '../Components/RichTextElement';

let getState = (props) => {
    return {
        textblock: TextblockStore.getTextblock(props.codename, props.language)
    };
};


class Textblock extends Component {
    constructor(props) {
        super(props);

        this.state = getState(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        TextblockStore.addChangeListener(this.onChange);
        TextblockStore.provideTextblock(this.props.codename, this.props.language);
    }

    componentWillUnmount() {
        TextblockStore.removeChangeListener(this.onChange);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.language !== nextProps.language) {
            TextblockStore.provideTextblock(this.props.codename, nextProps.language);
        }
    }

    onChange() {
        this.setState(getState(this.props));
    }

    render() {
        let textblock = this.state.textblock;
        if (typeof textblock  !== "undefined" && textblock.value.length < 15) {
            return (
                <div></div>
            );
        }
        if (!textblock) {
            return (
                <div className="component-spinner"></div>
            );
        }
        return (
            <RichTextElement element={textblock} />
        )
    }
}

export default Textblock;