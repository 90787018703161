import React, { Component } from 'react';

class FullPageText extends Component {
    render() {
        return (
            <div className="relative animatedParent animateOnce">
                <div className="center-parent">
                    <div className="center-content big animated fadeInUpShort">
                        {this.props.children}
                        {
                            this.props.showStar && <div className="text-center star-container"><div className="star"></div></div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FullPageText;