import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
//import {TweenMax, Power2, TimelineLite, TimelineMax, TweenLite} from "gsap";
//import "gsap";
import './Scrollmagic/animation.gsap.min';
import ScrollMagic from 'scrollmagic';
//import { window } from 'rxjs/operator/window';

class ScrollMagicWrapper extends Component {
    constructor(props) {
        super(props);
    
        this.initScroll = this.initScroll.bind(this);
    }

    initScroll() {
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        if (isChrome && $(window).width() > 768) {
            //if (typeof this.controller === "undefined" || this.controller == null) 
            {
                this.controller = new ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: 'onLeave'
                    }
                });
                var self = this;
                // get all slides
                var slides = document.querySelectorAll("section.panel");
        
                // create scene for every slide
                for (var i=0; i<slides.length; i++) {
                    if ($(slides[i]).parents(".scrollmagic-pin-spacer").length == 0) {
                        new ScrollMagic.Scene({
                                triggerElement: slides[i],
                                duration: 300
                            })
                            .setPin(slides[i])
                            .setTween($(slides[i]).find(".panel-image-container-wrapper"), 0.1, {padding: 20 }) 
                            //.addIndicators() // add indicators (requires plugin)
                            .addTo(self.controller);
                    }
                }
            }
        }
    }
    componentDidMount(prevProps) {
        this.initScroll();
    }
  
    componentDidUpdate(prevProps) {
        this.initScroll();
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollMagicWrapper)