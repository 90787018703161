import React, { Component } from 'react';
import Link from '../Components/LowerCaseUrlLink';
import AltarStore from "../Stores/Altar";

let getState = (props) => {
  return {
    altars: AltarStore.getAltars(props.language),
  };
};

class Altars extends Component {

  constructor(props) {
    super(props);

    this.state = getState(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    AltarStore.addChangeListener(this.onChange);
    AltarStore.provideAltars(this.props.language);
  }

  componentWillUnmount() {
    AltarStore.removeChangeListener(this.onChange);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      AltarStore.provideAltars(nextProps.language);
    }
  }

  onChange() {
    this.setState(getState(this.props));
  }

  render() {
    let altars = this.state.altars.map((altar, index) => {
      let title = altar.title.value;
      let imageLink = altar.teaserImage != null && altar.teaserImage !== "undefined" && altar.teaserImage.value[0] != null && altar.teaserImage.value[0] !== "undefined" ? altar.teaserImage.value[0].url : "";
      let link = `/kostel/${altar.urlPattern.value}`;

      return (
        <div className="altar-preview-item" key={index}>
            <Link to={link}>
              <span className="altar-preview-item-title">{title}</span>
              <figure className="altar-preview-item-image-container">
                <img alt={title} className="altar-preview-item-image" src={imageLink} />
              </figure>
            </Link>
        </div>
      );
    });

    return (
      <div id="altar-list" className="altar-list">
        {altars}
      </div>
    );
  }
}

export default Altars;