import React, { Component } from 'react';
import { translate } from 'react-translate'
import { Link } from 'react-router-dom';
import Textblock from '../Components/Textblock';
import MassInfo from '../Components/MassInfo';
import PrayerInfo from '../Components/PrayerInfo';
import FullPageText from '../Components/FullPageText';
import ProductListComponent from '../Components/ProductList';
import VyrobaSvice from '../Images/vyroba-svice.jpg';
import { withRouter } from 'react-router-dom';

import Work1Small from '../Images/work/small/work1.jpg';
import Work2Small from '../Images/work/small/work2.jpg';
import Work3Small from '../Images/work/small/work3.jpg';
import Work4Small from '../Images/work/small/work4.jpg';
import Work5Small from '../Images/work/small/work5.jpg';
import Work6Small from '../Images/work/small/work6.jpg';
import Work7Small from '../Images/work/small/work7.jpg';

import Work1 from '../Images/work/work1.jpg';
import Work2 from '../Images/work/work2.jpg';
import Work3 from '../Images/work/work3.jpg';
import Work4 from '../Images/work/work4.jpg';
import Work5 from '../Images/work/work5.jpg';
import Work6 from '../Images/work/work6.jpg';
import Work7 from '../Images/work/work7.jpg';

import Teaser1 from '../Images/work/work-teaser1.jpg';
import Teaser2 from '../Images/work/work-teaser2.jpg';
import Products from '../Data/products.json';

let productswithCategories = Products;

class ProductsPage extends Component {
    constructor(props){
        super(props)
            
        // Set initial state

        const urlParams = new URLSearchParams(window.location.search);
        const category = urlParams.get('category');
        if (typeof category !== "undefined" && category != null && category != "") {
            this.state = {data: productswithCategories.filter(
                function(el) {
                    return el.codename == category;
                }
            )};
        }
        else {
            this.state = {data: productswithCategories};
        }
        // Binding this keyword
        this.setCategory = this.setCategory.bind(this);
    }

    setCategory(codename){
        if (codename == "") {
            this.setState({data: productswithCategories});
            window.history.replaceState(null, "", window.location.pathname);
            return;
        }

        var newState = productswithCategories.filter(
            function(el) {
                return el.codename == codename;
            }
        );
        this.setState({data: newState});

        window.history.replaceState(null, "", window.location.pathname + "?category=" + codename);
        // const urlParams = new URLSearchParams(window.location.search);
        // urlParams.set('category', codename);
        // window.location.search = urlParams;
    }

    render() {
        var test = this.state;
        const renderedProductsCategories = [];
        for (let category of this.state.data) {
            renderedProductsCategories.push(
            <div className="col-xs-12 col-sm-12 product-category">
              <h3>{category.name}</h3>
              <h4>{category.description}</h4>
              <div className='row'>
                <ProductListComponent products={category.products} />
                    </div>
                </div>
            );
        }

        const renderedProductsCategoriesMenu = [];
        renderedProductsCategoriesMenu.push(
            <a className='product-category-menu-item' href onClick={() => this.setCategory("")}>Vše</a>
        );
        for (let category of productswithCategories) {
            renderedProductsCategoriesMenu.push(
                <a className='product-category-menu-item' href onClick={() => this.setCategory(category.codename)}>{category.name}</a>
            );
        }

        return (
            <div className="main-content">
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Produkty
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                <strong>Zdobíme kvalitní parafínové svíčky</strong> od firmy Lima. Ke zdobení používáme barevné voskové pláty a speciální barvy. Motivy a vzory jsou naše vlastní. Můžeme nazdobit svíce i podle přání objednavatele. 
                                Účtujeme poštovné. 
                                </p>
                                <p>
                                Objednávky posílejte na objednavky.mnisky@gmail.com
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={Teaser2} width="500px" alt="Práce" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <section>
                    <div className="work-photo-gallery" style={{paddingTop: "50px"}}>
                        <img src={Work1Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work1} data-fancybox-group="all" />
                        <img src={Work2Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work2} data-fancybox-group="all" />
                        <img src={Work3Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work3} data-fancybox-group="all" />
                        <img src={Work4Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work4} data-fancybox-group="all" />
                        <img src={Work5Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work5} data-fancybox-group="all" />
                        <img src={Work6Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work6} data-fancybox-group="all" />
                        <img src={Work7Small} alt="při práci" data-caption="při práci" data-fancybox="gallery" href={Work7} data-fancybox-group="all" />
                    </div>
                </section>
                <div className='margin-top-100'>
                    <div className='product-category-menu'>
                        {renderedProductsCategoriesMenu}
                    </div>
                    <div id="products" name="products">
                        {renderedProductsCategories}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductsPage;