import React, { Component } from 'react';
import BannsStore from '../Stores/Banns';
import RichTextElement from '../Components/RichTextElement';
import dateFormat from 'dateformat';
import { dateFormats } from '../Utilities/LanguageCodes'

let getState = (props) => {
  return {
    banns: BannsStore.getBannsItem(props.match.params.codename)
  };
};

let formatDate = (value) => {
  return dateFormat(value, "dd. mm. yyyy");
};
let counter = 0;
let bannsAttachmentItem = (attachmentUrl) => {
  return (
    <div key={counter++}>
        <a href={attachmentUrl}>stáhnout v PDF</a>
    </div>
  )
}

class BannsItem extends Component {
  constructor(props) {
    super(props);

    this.state = getState(props);
    this.onChange = this.onChange.bind(this);

    dateFormat.i18n = dateFormats[props.language] || dateFormats[0];
  }

  componentDidMount() {
    BannsStore.addChangeListener(this.onChange);
    BannsStore.provideBannsItem(this.props.match.params.codename);
  }

  componentWillUnmount() {
    BannsStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getState(this.props));
  }

  render() {
    let banns = this.state.banns;
    if (!banns) {
      return (
        <div className="bans-container-loading">loading</div>
      );
    }

    let richText = (text, cssClass) => {
      if (text.value.length > 0 && text.value !== "<p><br></p>") {
        return (
          <div className={cssClass}>
            <RichTextElement className="main-description-detail" element={text} />
          </div>  
        );
      }
      
      return null;
    }

    let createdWhen = (date) => {
      if (date.value != null && date.value.length > 0 && date.value !== "<p><br></p>") {
        return (
            formatDate(date.value)
        );
      }
      
      return null;
    }

    let bannsAttachmentItems = banns.attachments.assets
      .sort(function(a,b) {
          var x = a.name.toLowerCase();
          var y = b.name.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
      })  
      .map((bannsAsset) => {
        return (
          bannsAttachmentItem(bannsAsset.url)
        );
      });
    
    return (
          <div className="main-content">
              <div className="op-container animatedParent animateOnce">
                  <div className="row">
                      <div className="col-xs-12 col-sm-12">
                          <div className="title-print">
                              Ohlášky v klášterním kostele<br />
                              <strong>{banns.perex.value}</strong>
                          </div>
                          <h1 className="main-title print-no-display">
                              {banns.perex.value}
                          </h1>
                          <h2 className="print-text-center print-date">
                              {createdWhen(banns.timefrom)} – {createdWhen(banns.timeto)}
                          </h2>
                          {richText(banns.text, "banns-description")}

                          <div className="banns-table">
                            <div className="title-print">
                              Pořad bohoslužeb
                            </div>
                            <h2 className="title-print">
                                v klášterním kostele Nanebevzetí Panny Marie v Uherském Brodě
                            </h2>
                            <RichTextElement className="main-description-detail" element={banns.table} />
                          </div>  
                     
                          <div className="banns-attachments-container">
                              {bannsAttachmentItems}
                          </div>
                        
                      </div>
                  </div>
              </div>
              <a href="http://ub.op.cz/ohlasky" className="onlyprint print-footer-link">
                  ub.op.cz/ohlasky
              </a>
          </div>
    );
  }
}

export default BannsItem;