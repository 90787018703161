import Client from "../Client.js";
import { initLanguageCodeObject, defaultLanguage } from '../Utilities/LanguageCodes'

let changeListeners = [];
let textblocks = initLanguageCodeObject();

let notifyChange = () => {
    changeListeners.forEach((listener) => {
        listener();
    });
}

let fetchTextblock = (codename, language) => {
    var query = Client.item(codename);

    if (language) {
        query.languageParameter(language);
    }

    query.getObservable()
        .subscribe(response => {
            if (language) {
                textblocks[language][codename] = response.item["text"];
            } else {
                textblocks[defaultLanguage][codename] = response.item["text"];
            }
            notifyChange();
        });
}

class TextblockStore {

    // Actions

    provideTextblock(codename, language) {
        fetchTextblock(codename, language);
    }

    // Methods

    getTextblock(codename, language) {
        return textblocks[language][codename];
    }

    // Listeners

    addChangeListener(listener) {
        changeListeners.push(listener);
    }

    removeChangeListener(listener) {
        changeListeners = changeListeners.filter((element) => {
            return element !== listener;
        });
    }
}

export default new TextblockStore();