import React, { Component } from 'react';

class ResponsiveImage extends Component {
    disableContextMenu(e) {
        e.preventDefault();
        return false;
    }

    render() {
        return (
            <section className="panel">
                <div className="panel-image-global-container">
                    <div className="panel-image-container-wrapper">
                        <div className="panel-image-container">
                            <img src={this.props.img} alt={this.props.title} data-fancybox="gallery" href={this.props.img} 
                                data-fancybox-group="all" data-protect="true" onContextMenu={this.disableContextMenu} />
                        </div>
                    </div>
                    <div className="img-name">
                        Obr. {this.props.title}
                    </div>
                </div>
                <div className="description">
                    {this.props.children}
                </div>
            </section>
        );
    }
}

export default ResponsiveImage;