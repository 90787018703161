import React from 'react';
import { translate } from 'react-translate';
import Textblock from '../Components/Textblock';

const MassInfo = (props) => {
  return (
    <div className="mass-info">
        <strong>Bohoslužby:</strong><br />
        <Textblock codename="bohosluzby" language={props.language} />
    </div>
  )
}

export default translate("MassInfo")(MassInfo);