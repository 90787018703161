import Client from "../Client.js";
import { initLanguageCodeObject, defaultLanguage } from '../Utilities/LanguageCodes'

let changeListeners = [];
let facts = initLanguageCodeObject();

let notifyChange = () => {
    changeListeners.forEach((listener) => {
        listener();
    });
}

let fetchFact = (language) => {
    var query = Client.item('o_projektu');

    if (language) {
        query.languageParameter(language);
    }

    query.getObservable()
        .subscribe(response => {
            if (language) {
                facts[language][0] = response.item["facts"];
            } else {
                facts[defaultLanguage][0] = response.item["facts"];
            }
            notifyChange();
        });
}

class FactStore {

    // Actions

    provideFact(language) {
        fetchFact(language);
    }

    // Methods

    getFact(language) {
        return facts[language][0];
    }

    // Listeners

    addChangeListener(listener) {
        changeListeners.push(listener);
    }

    removeChangeListener(listener) {
        changeListeners = changeListeners.filter((element) => {
            return element !== listener;
        });
    }
}

export default new FactStore();