import React, { Component } from 'react';
import FactStore from '../Stores/Fact';
import RichTextElement from '../Components/RichTextElement';

let getState = (props) => {
  return {
    fact: FactStore.getFact(props.language)
  };
};

class About extends Component {
  constructor(props) {
    super(props);

    this.state = getState(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    FactStore.addChangeListener(this.onChange);
    FactStore.provideFact(this.props.language);
  }

  componentWillUnmount() {
    FactStore.removeChangeListener(this.onChange);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      FactStore.provideFact(nextProps.language);
    }
  }

  onChange() {
    this.setState(getState(this.props));
  }

  render() {
    let fact = this.state.fact;
    console.log(fact);
    if (!fact) {
      return (
        <div className="container"><div className="page-spinner"></div></div>
      );
    }
    return (
      <div className="main-content">
        <div className="op-container animatedParent animateOnce">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <h1 className="main-title ">
                Kontakt
              </h1>
              <RichTextElement className="main-description-detail" element={fact} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;