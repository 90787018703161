import {  ContentItem } from 'kentico-cloud-delivery';
import {  resolveContentLink } from '../Utilities/ContentLinks';

export class Photogallery extends ContentItem {
   constructor(){
        super({
            propertyResolver: ((fieldName) => {
                if (fieldName === 'main_photo'){
                    return 'mainPhoto';
                }

                if (fieldName === 'photos'){
                    return 'photos';
                }

                if (fieldName === 'description'){
                    return 'description';
                }

                if (fieldName === 'author'){
                    return 'author';
                }

                if (fieldName === 'createdWhen'){
                    return 'createdWhen';
                }

                if (fieldName === 'url'){
                    return 'url';
                }
            }),
            linkResolver: (link) => resolveContentLink(link)
        })    
    }
}