import React, { Component } from 'react';
import BannsComponent from '../Components/Banns';

class Banns extends Component {
  render() {
    return (
      <div className="main-content">
        <div className="op-container animatedParent animateOnce">
          <div className="row">
            <div className="col-xs-12">
              <div style={{ "padding": "0 15px"}}>
                <h1 className="main-title">
                  Ohlášky
                </h1>
                <BannsComponent language={this.props.language} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
} 

export default Banns;