import React, { Component } from 'react';
import TextStore from '../Stores/Text';
import RichTextElement from '../Components/RichTextElement';

let getState = (props) => {
  return {
    text: TextStore.getText(window.location.pathname.replace("/", ""), props.language)
  };
};

class TextPage extends Component {
  constructor(props) {
    super(props);

    this.state = getState(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    TextStore.addChangeListener(this.onChange);
    TextStore.provideText(window.location.pathname.replace("/", ""), this.props.language);
  }

  componentWillUnmount() {
    TextStore.removeChangeListener(this.onChange);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.language !== nextProps.language) {
      TextStore.provideFact(nextProps.language);
    }
  }

  onChange() {
    this.setState(getState(this.props));
  }

  render() {
    let text = this.state.text;

    if (!text) {
      return (
        <div className="container"><div className="page-spinner"></div></div>
      );
    }
    return (
      <div className="main-content">
        <div className="op-container animatedParent animateOnce">
          <div className="row">
            <div className="col-xs-12">
              <div style={{ "padding": "0 15px"}}>
                <h1 className="main-title">
                  {text.title.value}
                </h1>
                <RichTextElement className="main-description-detail" element={text.text} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextPage;