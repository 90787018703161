import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import ScrollMagicWrapper from './ScrollMagicWrapper';
import AnimateItWrapper from './AnimateItWrapper';

// fancybox
import fancybox from './jquery.fancybox';
import './css/jquery.fancybox.css';

import Footer from './Components/Footer';
import HomePage from './Pages/Home';
import MonasteryPage from './Pages/Monastery';
import ReconstructionPage from './Pages/Reconstruction';
import ChurchPage from './Pages/Church';
import AltarDetailPage from './Pages/AltarDetail';
import HistoryPage from './Pages/History';
import BellsPage from './Pages/Bells';
import PhotogalleriesPage from "./Pages/Photogalleries"
import PhotogalleryPage from "./Pages/Photogallery"
import PilgrimagePage from './Pages/Pilgrimage';
import Menu from './Components/Menu.js';
import AboutPage from './Pages/About';
import TextPage from './Pages/TextPage';
import BannsPage from './Pages/Banns';
import BannsItemPage from './Pages/BannsItem';
import CommunityPage from './Pages/Community';
import ProductsPage from './Pages/Products';

// <TransitionGroup component="main" className="page-main">
// <CSSTransition key={currentKey} timeout={timeout} classNames="fade" appear unmountOnExit={true}>
// </CSSTransition>
// </TransitionGroup>

const App = (props) => {
  return (
    <div>
      <Menu language={props.language} changeLanguage={props.changeLanguage} />
      <div className="content-min-height">
      <ScrollToTop>
      <ScrollMagicWrapper>
      <AnimateItWrapper>
        <Switch location={window.location}>
          <Route path="/:lang?/historie" render={(matchProps) => <HistoryPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/kostel/:altarSlug" render={(matchProps) => <AltarDetailPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/kostel" render={(matchProps) => <ChurchPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/komunita" render={(matchProps) => <CommunityPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/zvony" render={(matchProps) => <BellsPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/fotogalerie/:photogallerySlug" render={(matchProps) => <PhotogalleryPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/fotogalerie" render={(matchProps) => <PhotogalleriesPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/ruzencova" render={(matchProps) => <PilgrimagePage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/ruzencova-pout" render={(matchProps) => <PilgrimagePage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/o-projektu" render={() => <AboutPage language={props.language} />} />
          <Route path="/:lang?/kontakt" render={() => <AboutPage language={props.language} />} />
          <Route path="/:lang?/ohlasky/:codename" render={(matchProps) => <BannsItemPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/ohlasky" render={() => <BannsPage language={props.language} />} />
          <Route path="/:lang?/benedikt-teltschik" render={() => <TextPage language={props.language} />} />
          <Route path="/:lang?/klaster-2018" render={(matchProps) => <MonasteryPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/prestavba" render={(matchProps) => <ReconstructionPage {...matchProps} language={props.language} />} />
          <Route path="/:lang?/vyhnani-dominikanu-z-uherskeho-brodu-v-roce-1950" render={() => <TextPage language={props.language} />} />
          <Route path="/:lang?/povest-o-zalozeni-mesta-povest-o-ruzovem-keri" render={() => <TextPage language={props.language} />} />
          <Route path="/:lang?/jan-pavel-ii-na-vecnou-pamatku" render={() => <TextPage language={props.language} />} />
          <Route path="/:lang?/produkty" render={() => <ProductsPage language={props.language} />} />
          <Route exact path="/:lang?" render={(matchProps) => <HomePage  {...matchProps} language={props.language} />} />
          <Route path="/images/*" onEnter={() => window.location.reload()} />
          {/* <Route path="*" render={(props) => { return <Redirect to="/" push /> }} /> */}
        </Switch>
        </AnimateItWrapper>
        </ScrollMagicWrapper>
        </ScrollToTop>
      </div>
      <Footer language={props.language} changeLanguage={props.changeLanguage} />
    </div>
  );
}
export default App;