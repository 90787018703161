import React from 'react';
import Link from '../Components/LowerCaseUrlLink';
import { translate } from 'react-translate';

const Footer = (props) => {
  return (
    <div className="footer-wrapper">
        <footer>
            <div className="container copyright">
                2018, <Link to={`/o-projektu`}><span>o projektu</span></Link>
            </div>
        </footer>
    </div>
  );
}

export default translate("Footer")(Footer);