import React, { Component } from 'react';
import AltarStore from "../Stores/Altar";
import dateFormat from 'dateformat';
import { dateFormats } from '../Utilities/LanguageCodes';
import animateIt from '../css3-animate-it.js';
import RichTextElement from '../Components/RichTextElement';

let getState = (props) => {
    return {
        altar: AltarStore.getAltar(props.match.params.altarSlug, props.language)
    };
};

class AltarDetail extends Component {
    constructor(props) {
        super(props);

        this.state = getState(props);
        this.onChange = this.onChange.bind(this);
        dateFormat.i18n = dateFormats[props.language] || dateFormats[0];
    }

    componentDidMount() {
        AltarStore.addChangeListener(this.onChange);
        AltarStore.provideAltar(this.props.match.params.altarSlug, this.props.language);
    }

    componentWillUnmount() {
        AltarStore.removeChangeListener(this.onChange);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.language !== nextProps.language) {
            AltarStore.provideAltar(this.props.match.params.altarSlug, nextProps.language);
            dateFormat.i18n = dateFormats[nextProps.language] || dateFormats[0];
        }
    }

    componentDidUpdate() {
        animateIt.reloadData();
    }

    onChange() {
        this.setState(getState(this.props));
    }

    render() {
        let altar = this.state.altar;
        if (!altar) {
            return (
              <div className="container"><div className="page-spinner"></div></div>
            );
        }
        return (
            <main id="main">
                <div className="main-content">
                    <div className="op-container animatedParent animateOnce">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <h1 className="main-title ">
                                    {altar.title.value}
                                </h1>
                                <div className="main-perex animated fadeInRightShort">
                                    <RichTextElement className="main-description-detail" element={altar.description} />

                                    <div className="text-center star-container">
                                        <div className="star"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                    <img src={altar.teaserImage.value[0].url} width="500px" alt={altar.title.value} />
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="margin-top-50 margin-bottom-30">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-6">
                                <div className="full-width-image-container">
                                    <img src={altar.mainImage.value[0].url} width="500px" alt={altar.title.value} />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 animatedParent animateOnce">
                                <div className="center-parent ">
                                    <div className="center-content left animated fadeInLeftShort margin-top-50 margin-bottom-50">
                                        <RichTextElement element={altar.longDescription} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row photo-grid-container">
                            {altar.gallery.value.map((item, i) => {
                                return (
                                  <div className="col-xxs-12 col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                        <img key={i} src={item.url} data-fancybox="gallery" href={item.url} data-fancybox-group="altar" alt={"altar" + i} />
                                  </div>
                                )})
                            }
                    </div>
                </div>
            </main>
        );
    }
}

export default AltarDetail;