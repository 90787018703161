import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import ResponsiveImage from '../Components/ResponsiveImage';
import Textblock from '../Components/Textblock';
import BackgroundImage from '../Images/ruzencova-lide2.jpg';
import PilgrimageDetailImage from '../Images/obrazy-klaster/pout-cerny-detail2.jpg';
import PilgrimageWholeImage from '../Images/obrazy-klaster/pout-cerny-celek.jpg';
import OldestPhotoImage from '../Images/ruzencova-pout-1902-autor-neznamy.jpg';
import PilgrimageHistoricImage from '../Images/ruzencova-pout-pred-kostelem.jpg';
import HistoryCroppedImage from '../Images/ruzencova-historie-vyrez.jpg';


import CrownsRomeImage from '../Images/korunky-rim.jpg';

import MariaImage from '../Images/maria-orez.jpg';
import Tajemstvi11 from '../Images/tajemstvi/small/1-1-Z-ducha-sv-pocala.jpg';
import Tajemstvi12 from '../Images/tajemstvi/small/1-2-alzbetu-navstivila.jpg';
import Tajemstvi13 from '../Images/tajemstvi/small/1-3-v-betleme-porodila.jpg';
import Tajemstvi14 from '../Images/tajemstvi/small/1-4-v-chrame-obetovala.jpg';
import Tajemstvi15 from '../Images/tajemstvi/small/1-5-v-chrame-nalezla.jpg';
import Tajemstvi31 from '../Images/tajemstvi/small/3-1-krvi-potil.jpg';
import Tajemstvi32 from '../Images/tajemstvi/small/3-2-bicovan.jpg';
import Tajemstvi33 from '../Images/tajemstvi/small/3-3-trnim-korunovan.jpg';
import Tajemstvi34 from '../Images/tajemstvi/small/3-4-Tezky-kriz-nesl.jpg';
import Tajemstvi35 from '../Images/tajemstvi/small/3-5-ukrizovan.jpg';
import Tajemstvi41 from '../Images/tajemstvi/small/4-1-z-mrtvych-vstal.jpg';
import Tajemstvi42 from '../Images/tajemstvi/small/4-2-na-nebe-vstoupil.jpg';
import Tajemstvi43 from '../Images/tajemstvi/small/4-3-ducha-sv-seslal.jpg';
import Tajemstvi44 from '../Images/tajemstvi/small/4-4-do-nebe-vzal.jpg';
import Tajemstvi45 from '../Images/tajemstvi/small/4-5-v-nebi-korunoval.jpg';

import Tajemstvi11Big from '../Images/tajemstvi/big/1-1-Z-ducha-sv-pocala.jpg';
import Tajemstvi12Big from '../Images/tajemstvi/big/1-2-alzbetu-navstivila.jpg';
import Tajemstvi13Big from '../Images/tajemstvi/big/1-3-v-betleme-porodila.jpg';
import Tajemstvi14Big from '../Images/tajemstvi/big/1-4-v-chrame-obetovala.jpg';
import Tajemstvi15Big from '../Images/tajemstvi/big/1-5-v-chrame-nalezla.jpg';
import Tajemstvi31Big from '../Images/tajemstvi/big/3-1-krvi-potil.jpg';
import Tajemstvi32Big from '../Images/tajemstvi/big/3-2-bicovan.jpg';
import Tajemstvi33Big from '../Images/tajemstvi/big/3-3-trnim-korunovan.jpg';
import Tajemstvi34Big from '../Images/tajemstvi/big/3-4-Tezky-kriz-nesl.jpg';
import Tajemstvi35Big from '../Images/tajemstvi/big/3-5-ukrizovan.jpg';
import Tajemstvi41Big from '../Images/tajemstvi/big/4-1-z-mrtvych-vstal.jpg';
import Tajemstvi42Big from '../Images/tajemstvi/big/4-2-na-nebe-vstoupil.jpg';
import Tajemstvi43Big from '../Images/tajemstvi/big/4-3-ducha-sv-seslal.jpg';
import Tajemstvi44Big from '../Images/tajemstvi/big/4-4-do-nebe-vzal.jpg';
import Tajemstvi45Big from '../Images/tajemstvi/big/4-5-v-nebi-korunoval.jpg';
import PilgrimageProgram from '../Images/ruzencova-pout-2024-program.jpg';

class PilgrimagePage extends Component {
    render() {
    return (
            <div className="main-content">
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Růžencová pouť
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>...se koná každoročně první neděli v říjnu.
                                </p>
                                <p>
                                    Vznik Růžencové pouti kladou kronikáři do první poloviny jedenáctého století (viz pověst o růžovém keři)
                                </p>
                                <p>
                                    Největší ozdobou chrámu je barokní obraz Černé Madony z přelomu 17. a 18. století, který je volnou kopií původního obrazu.
                                    Podle živé legendy byl brodský obraz uloupen za nejasných okolností na začátku 14. století 
                                    (pravděpodobně za vpádu Matúša Čáka Trenčianského) 
                                    a dostal se z Uherského Brodu do Čenstochové v Polsku.
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={HistoryCroppedImage} width="500px" alt="history" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="margin-top-50 relative animatedParent animateOnce gray-content-container" style={{ backgroundImage: "url(" + BackgroundImage + ")" }}>
                    <div className="gray-content animated fadeInLeftShort">
                        Téměř tisíciletá tradice je stále živá<br />
                        Každoročně na růžencovou pouť doputují stovky poutníků
                    </div>
                </div>
                <div className="program">
                    <h2>
                        Program růžencové pouti
                    </h2>
                    <div className="row text-center animatedParent animateOnce">
                        <div className="col-xs-12 col-sm-12">
                            <section>
                                <div className="description" style={{textAlign: "left", padding: "0 20px 100px 20px"}}>
                                    <Textblock codename="program_pouti_fullpage" language={this.props.language} />
                                </div>
                            </section>
                        </div>
                        <div className="col-xs-12 col-sm-6 animated fadeInRightShort">
                            <div className="sticky-content">
                                <Textblock codename="program_pouti_sobota" language={this.props.language} />
                                {/*<h3>
                                    Sobota
                                </h3>
                                <div className="program-line">
                                    <div className="program-time">
                                        18:00
                                    </div>
                                    <div className="program-itself">
                                        průvod se svícemi od farního do klášterního kostela
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        19:00
                                    </div>
                                    <div className="program-itself">
                                        mše sv. farnosti
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        21:00 - 23:30
                                    </div>
                                    <div className="program-itself">
                                        noc mládeže s Marií <br />
                                        <div className="program-description">(adorace, modlitba chval, růženec, setkání s knězem)</div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 animated fadeInLeftShort delay-250">
                            <div className="sticky-content">
                                <Textblock codename="program_pouti_nedele" language={this.props.language} />
                                {/*<h3>
                                    Neděle
                                </h3>
                                <div className="program-line">
                                    <div className="program-time">
                                        6:00
                                    </div>
                                    <div className="program-itself">
                                        mše svatá <br />
                                        <div className="program-description">(P. RNDr. ThLic. Jan Rajlich OP)</div>
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        7:30
                                    </div>
                                    <div className="program-itself">
                                        mše svatá farnosti<br />
                                        <div className="program-description">(P. Mgr. Josef Pelc)</div>
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        9:00
                                    </div>
                                    <div className="program-itself">
                                        mše svatá ve slovenském jazyce
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        10:00
                                    </div>
                                    <div className="program-itself">
                                        průvod krojovaných poutníků od GJAK do klášterní zahrady
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        10:30
                                    </div>
                                    <div className="program-itself">
                                        mše svatá
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        13:00
                                    </div>
                                    <div className="program-itself">
                                        loretánská litanie a svátostné požehnání
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        15:00
                                    </div>
                                    <div className="program-itself">
                                        mše sv. s bohoslovci ze semináře v Olomouci
                                    </div>
                                </div>
                                <div className="program-line">
                                    <div className="program-time">
                                        18:30
                                    </div>
                                    <div className="program-itself">
                                        mše sv.
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                       
                    </div>
                </div>
                <ResponsiveImage img={PilgrimageProgram} title="Program pouti" />
                <div className="margin-top-50 relative animatedParent animateOnce gray-content-container" style={{ backgroundImage: "url(" + PilgrimageDetailImage + ")", marginBottom: '100px' }}>
                    <div className="gray-content animated fadeInLeftShort">
                        Krojovaná pouť 1934, malíř Václav Černý
                    </div>
                </div>
                <div className="video-iframe-wrapper-container">
                    <div className="video-iframe-wrapper">
                        <Iframe width="100%"  url="https://www.youtube.com/embed/z6Pg1YnHWTk" frameBorder="0" allowFullScreen></Iframe>
                    </div>
                </div>
                <div className="margin-top-60 margin-bottom-60">
                    <div className="long-description op-container animatedParent animateOnce ">
                        <div className="col-xs-12">
                            <p>
                                <strong>
                                    Uherský Brod je spojen s hlubokou úctou k Panně Marii. Proto byl také nazýván Městem Královny Svatého Růžence. Tradice mariánských poutí je zde živá celá staletí. Jako poutní místo je v literatuře znám od konce 17. a uváděn od 2. poloviny 19. století. 
                                </strong>
                            </p>
                            <p>
                                K nejstarším poutím u příležitosti srpnové slavnosti Nanebevzetí Panny Marie přibyly od 17. století také říjnové poutě k Panně Marii růžencové, dříve nazývané Slavností Sv. růžence. Dosud známá první zmínka o takové pouti se váže k roku 1658, kdy se pouti – podobně jako v následujících letech – zúčastnili jezuité z nedalekého Uherského Hradiště. Ti zde vypomáhali také v roce 1725, kdy zde zpovídalo cekem 24 zpovědníků! V letech 1739–1742 se třikrát růžencových poutí zúčastnil také biskup Jakub Arnošt z Lichtenštejna-Kastelkornu (o rok později korunoval Marii Terezii českou královnou). Ten 28. srpna 1742 vysvětil růžencový oltář s obrazem Panny Marie. 
                            </p>
                            <p>
                                Podle písemně zaznamenaných kronikářských vyprávění z přelomu 17. a 18. století se nejstarší poutě konaly v úzké souvislosti právě s ikonou, kterou měl již ve 13. století nově založenému dominikánskému klášteru darovat uherský král Ondřej II. Tato ikona se dosti pravděpodobně stala předlohou pro brodský obraz Panny Marie s Ježíškem, který byl namalován na konci 17. století (cca 1680–1700). Ani s novými poznatky se nedá vyloučit, že touto původní ikonou mohla být starobylá slavná ikona Panny Marie, dnes uctívaná v Čenstochové. 
                            </p>
                            <p>
                                Jak uvádí klášterní kronika, růžencové bratrstvo v Uherském Brodě 12. dubna 1744 slavnostně obnovilo tzv. mariánský senát. Za tři roky poté Josef Winterhalder st. vytvořil kolem obrazu Panny Marie oltář, jak jej známe dnes – s 15 dalšími obrázky růžencových tajemství.  Je známo, že poutníci přicházeli jak na růžencovou slavnost, tak i do tehdejšího farního kostela sv. Jana Křtitele – dnes Dolní kostel (1771). 
                            </p>
                            <p>
                                Přání Svatého otce Lva XIII., aby růžencová bratrstva „konala veřejné náboženské průvody“ bylo v Uherském Brodě naplněno růžencovými nedělemi – 1. v měsíci, kdy se v průvodech růžencového bratrstva nosilo 15 obrázků růžencových tajemství. Růžencové bratrstvo bylo v klášteře obnoveno 1879 a trvalo do 50. let 20. století. Patřilo do něj tisíce lidí z Uherského Brodu a okolí. Růžencový průvod byl také vyvrcholením růžencové pouti – odpoledne při růžencové slavnosti. Článek z r. 1905 při této příležitosti připomíná poutníky jak z blízkého okolí, kteří sem přicházeli s hudbou a v pestrých krojích, tak i poutníky z nedalekých Uher. Uherský Brod je srovnáván s poutěmi ke Sv. Antonínku nebo na Velehrad. Pestrost poutníků a tehdejší atmosféru dokládá také barevné plátno od akad. mal. Václava Černého „Růžencová pouť v Uherském Brodě“ (1934). Množství poutníků z celého Uherskobrodska a od Uherského Hradiště, Napajedel, Strážnice, Hodonína, Kyjova se zde připomíná v době 2. světové války. V r. 1947 se mělo pouti do Uherského Brodu zúčastnit snad až 20 000 poutníků! 
                            </p>
                            <p>
                                Na konci 20. století je mariánský obraz Královny posvátného růžence <Link to={`/jan-pavel-ii-na-vecnou-pamatku`} target="_blank">korunován papežskými korunkami posvěcenými v Římě sv. Janem Pavlem II. 
                                Je udělena výsada odpustků pro poutníky, kteří navštíví dominikánský chrám 1. neděli v květnu.</Link> Slavná korunovace obrazu pak proběhla při růžencové pouti 5. října 1997. 
                                Obraz Královny posvátného růžence je od té doby součástí pravidelných klášterních pobožností, vždy první sobotu v měsíci. 
                            </p>
                        </div>
                    </div> 
                </div>
                <ResponsiveImage img={OldestPhotoImage} title="Nejstarší dochovaná fotografie z pouti. 1902, autor neznámý, z archivu Muzea J. A. Komenského v Uherském Brodě" />
                <ResponsiveImage img={PilgrimageHistoricImage} title="z archivu Muzea J. A. Komenského v Uherském Brodě" />
                <ResponsiveImage img={PilgrimageWholeImage} title="Růžencová pouť 1934, autor Václav Černý" />
                <div className="video-iframe-wrapper-container padding-top-50">
                    <div className="video-iframe-wrapper">
                        <Iframe width="100%"  url="https://www.youtube.com/embed/xVBLSH1EW00" frameBorder="0" allowFullScreen></Iframe>
                    </div>
                </div>
                <ResponsiveImage img={CrownsRomeImage} title="Při generální audieneci u papeže Jana Pavla II. Skleněný předmět drží starosta Jiří Veselý, korunky P. Bernard Špaček OP." />
                <ResponsiveImage img={MariaImage} title="Obraz panny Marie Růžencové" />
                <div className="rosary-mystery-container">
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi11Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi11} alt="Kterého jsi z ducha svatého počala" title="Kterého jsi z ducha svatého počala" />
                    </div>   
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi12Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi12} alt="Se kterým jsi Alžbětu navštívila" title="Se kterým jsi Alžbětu navštívila" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi13Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi13} alt="Kterého jsi v Betlémě porodila" title="Kterého jsi v Betlémě porodila" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi14Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi14} alt="Kterého jsi v chrámě obětovala" title="Kterého jsi v chrámě obětovala" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi15Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi15} alt="Kterého jsi v chrámě nalezla" title="Kterého jsi v chrámě nalezla" />
                    </div>
                
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi31Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi31} alt="Který se pro nás krví potil" title="Který se pro nás krví potil" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi32Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi32} alt="Který byl pro nás bičován" title="Který byl pro nás bičován" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi33Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi33} alt="Který byl pro nás trním korunován" title="Který byl pro nás trním korunován" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi34Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi34} alt="Který pro nás nesl těžký kříž" title="Který pro nás nesl těžký kříž" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi35Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi35} alt="Který byl pro nás ukřižován" title="Který byl pro nás ukřižován" />
                    </div>
                    
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi41Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi41} alt="Který z mrtvých vstal" title="Který z mrtvých vstal" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi42Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi42} alt="Který na nebe vstoupil" title="Který na nebe vstoupil" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi43Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi43} alt="Který ducha sv. seslal" title="Který ducha sv. seslal" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi44Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi44} alt="Který tě, panno, do nebe vzal" title="Který tě, panno, do nebe vzal" />
                    </div>
                    <div className="rosary-mystery" data-fancybox="gallery" href={Tajemstvi45Big} data-fancybox-group="mystery">
                        <img src={Tajemstvi45} alt="Který tě v nebi korunoval" title="Který tě v nebi korunoval" />
                    </div>
                </div>

                
            </div>
        );
    } 
}
export default PilgrimagePage;