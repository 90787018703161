import React, { Component } from 'react';
import FullPageText from '../Components/FullPageText';
import ResponsiveImage from '../Components/ResponsiveImage';

import WellImage from '../Images/klaster/studna.jpg';
import MniskyImage from '../Images/mnisky/mnisky.JPG';
import Mnisky2Image from '../Images/mnisky/mnisky2.JPG';
import VisualisationImage from '../Images/mnisky/zrekonstruovany-klaster.JPG';
import HrubaStavba from '../Images/rekonstrukce/rekontrukce-hruba-stavba.jpg';
import FromStairs from '../Images/community/from-stairs.jpg';
import Prayer from '../Images/community/prayer.jpg';
import Klausura from '../Images/community/klausura.jpg';
import Nespory from '../Images/community/nespory.jpg';
import All from '../Images/community/all.jpg';
import Chor2 from '../Images/community/chor2.jpg';
import Dominik from '../Images/community/dominik.jpg';
import Ruzena from '../Images/community/ruzena.jpg';
import Justyna from '../Images/community/justyna.jpg';
import Svice from '../Images/community/svice.jpg';

import Iframe from 'react-iframe';

class CommunityPage extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Mnišky Kazatelského řádu
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                    Náš klášter je kontemplativním klášterem mnišek Kazatelského řádu a podléhá magistrovi řádu (fr. Gerard Timoner).
                                </p>
                                <p>
                                    První dům dominikánských mnišek založil svatý Dominik ve Francii v Prouille na samém začátku řádu r. 1206.
                                </p>
                                <p>
                                    Poslání dominikánských mnišek vychází ze Základní ústavy mnišek:
                                    <ul>
                                        <li>
                                            Úsilí o dokonalou lásku k Bohu a k bližním, která se účinně přičiňuje o spásu lidí.
                                        </li>
                                        <li>
                                            Hledání Boha ve skrytosti
                                        </li>
                                        <li>
                                            V srdci církve růst v lásce
                                        </li>
                                        <li>
                                            Prorocky hlásat svým životem, že "Kristus jediný je blaženost".
                                        </li>
                                        <li>
                                            Horlivá snaha o studium posvátné pravdy, které živí kontemplativní modlitbu.
                                        </li>
                                    </ul>
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={FromStairs} width="100%" alt="cela" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="video-iframe-wrapper-container full" style={{ marginTop: '50px' }}>
                    <div className="video-iframe-wrapper">
                        <Iframe width="100%"  url="https://www.youtube.com/embed/Hab3i3rDjas" frameBorder="0" allowFullScreen></Iframe>
                    </div>
                </div>
                <div className="full-width-image-container" style={{ marginTop: '50px' }}>
                    <img src={Prayer} alt="modlitba" />
                </div>
                <FullPageText showStar={false}>
                    Jsme kontemplativní komunita dominikánských mnišek.
                    Všem lidem <strong>nabízíme účast na</strong>:<br /><br />
                    <strong>
                        <ul>
                            <li>
                                každodenním slavení mše svaté
                            </li>
                            <li>
                                slavení dominikánské mnišské liturgie
                            </li>
                            <li>
                                každodenní eucharistické adoraci
                            </li>
                            <li>
                                modlitbě posvátného růžence
                            </li>
                        </ul>
                    </strong>
                </FullPageText>
                <div className="full-width-image-container" style={{ marginTop: '50px' }}>
                    <img src={Klausura} alt="klauzura" />
                </div>
                {/* <div className="row photo-grid-container margin-top-100 ">
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={Mnisky2Image} data-fancybox-group="vestry">
                        <img src={Mnisky2Image} alt="Mnišky ve Znojmě" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={MniskyImage} data-fancybox-group="vestry">
                        <img src={MniskyImage} alt="Mnišky ve Znojmě" />
                    </div>
                    <div className="clearfix"></div>
                </div> */}

                <div className="op-container animatedParent animateOnce ">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="padding-top-50 margin-top-50 margin-bottom-50 full-page-text">
                                Řád Kazatelů založil sv. Dominik Guzman na počátku 13. století. Jejich posláním je dodnes modlitba a kontemplativní život, kterým podporují účinnost kázání bratří dominikánů a vyprošují Boží milost všem potřebným. Jejich skrytý každodenní společný život je protkán slavením liturgie, modlitbou, prací a studiem posvátných pravd.
                                <br /><br />
                                K obnovení mnišek Kazatelského řádu v naší zemi došlo po dlouhém úsilí až během Druhé světové války především zásluhou dominikánů P. Metoděje Habáně a P. Jiřího M. Veselého. Počátky obnovy byly velmi obtížné, komunita začínala ve veliké chudobě v nevyhovujících budovách. Ke kanonickému schválení došlo 30. dubna 1948 a už v září 1950 byly mnišky zásahem komunistického režimu vyvezeny do soustřeďovacích klášterů a nasazeny do továren. Během těchto let se komunita mnohokrát nuceně stěhovala. Po pádu totalitního režimu v roce 1989 se komunita usadila v klášteře bratří dominikánů ve Znojmě, protože nikdy neměla vlastní budovu. Bratři mniškám přenechali klášter v Uherském Brodě,
                                jehož prostory i areál lépe vyhovují způsobu života mnišek dominikánek
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width-image-container" style={{ marginTop: '50px' }}>
                    <img src={Nespory} alt="Nešpory" />
                </div>
                <FullPageText showStar={true}>
                    Na živobytí si vyděláváme vlastní prací. Zdobíme svíce, vyšíváme
                    křestní roušky, šijeme kališní prádlo a kněžské štoly, vyrábíme
                    růžence. Jsme vděčné, když nás zakoupením našich produktů
                    podpoříte
                </FullPageText>
                <div className="full-width-image-container" style={{ marginTop: '50px' }}>
                    <img src={All} alt="Komunita sester" />
                </div>
                <FullPageText showStar={false}>
                    Náš řeholní život vychází z řehole sv. Augustina, která dává velký důraz na společný život.
                    <br /><br />
                    Ideálem tohoto života je jednota sester, které mají jedno srdce a jednu duši, a která přerůstá v jednotu s řádem a celou církví.
                    <br /><br />
                    Tato jednomyslnost má být příkladem všeobecného smíření v Kristu. Vzorem je apoštolská církev v Jeruzalémě, kterou shromáždil Duch svatý.
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={Justyna} alt="sr. Justýna" title="sr. Justýna" />
                </div>
                <FullPageText showStar={false}>
                    V liturgii se zpřítomňuje tajemství spásy, a to především v eucharistii, která je památkou Kristovy smrti a zmrtvýchvstání. Proto je slavnostní konání liturgie opěrným bodem a duší celého našeho života.
                    <br /><br />
                    Jako mnišky se modlíme celou liturgii hodin podle starobylé tradice chórovým způsobem.
                    <br /><br />
                    Ve vnitřní modlitbě následujeme sv. Dominika jako on následoval Krista Pána, který ve dnech svého pozemského života přednášel s naléhavým voláním a se slzami vroucí modlitby k Bohu.
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={Dominik} alt="sv. Dominik" title="sv. Dominik" />
                </div>
                <FullPageText showStar={false}>
                    Veřejnými sliby evangelijních rad čistoty, chudoby a poslušnosti jsme zasvěceny Bohu. V našem řádu se výslovně jmenuje jenom slib poslušnosti, protože ostatní dva jsou již zahrnuty v něm.
                    <br /><br />
                    V čistotě, pokoře srdce a v živé a vytrvalé kontemplaci milujeme Krista, který je v Otcově lůně.
                    <br /><br /><br /><br /><i>„… skládám profesi
                        a slibuji poslušnost Bohu,<br />
                        blahoslavené Panně Marii,<br />
                        svatému Dominiku,<br />
                        magistrovi řádu bratří Kazatelů<br />
                        a tobě, sestro …“<br /></i>
                </FullPageText>

                <div className="full-width-image-container margin-bottom-30">
                    <img src={Ruzena} alt="sr. Růžena" title="sr. Růžena" />
                </div>
                <FullPageText showStar={false}>
                    Studium, které svatý Dominik doporučil již prvím sestrám, je pevnou součástí naší spirituality. Světlem a pramenem studia je Bůh. <br /><br />
                    Studujeme především posvátné texty, v nichž můžeme nazírat na tajemství spásy.
                </FullPageText>

                <div className="full-width-image-container margin-bottom-30">
                    <img src={Svice} alt="Velikonoční vigilie" title="Velikonoční vigilie" />
                </div>
                <FullPageText showStar={false}>
                    Formace v komunitě jako ve škole lásky postupuje v několika stupních, které mají připravit sestru na úplné zasvěcení se Bohu. <br /><br />
                    Prvním stupněm uvedení je postulát, který trvá šest měsíců až rok. Pak postulantka přijímá řeholní hábit, bílý závoj a nové jméno čímž začíná dvouletý noviciát. <br /><br />
                    První profesi skládáme na 3 roky, pak ji obnovujeme na 1 rok, po kterém skládáme slavné sliby, kterými se zasvěcujeme Bohu v řádu až do smrti.
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={Chor2} alt="Chór" title="Chór" />
                </div>
                <FullPageText showStar={false}>
                    Naším hlavním posláním je modlitba, kterou jsme přidruženy k apoštolátu bratří Řádu Kazatelů a celé dominikánské rodiny. Kromě toho neseme v srdci i aktuální radosti i starosti Církve a všech, kdo nás prosí o modlitbu.
                    <br /><br />
                    Rády do svých modliteb zahrneme i Vás.
                    <br /><br />
                    Vaše úmysly a prosby o modlitbu můžete vložit do dřevěné schránky, která je umístěná v zadní části kostela nebo poslat na náš email mnisky@op.cz.
                    <br /><br />
                    Mešní intence zapisuje rektor klášterního kostela zpravidla po skončení mše sv.
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={WellImage} alt="Studna v klášterní zahradě" title="Studna v klášterní zahradě" />
                </div>
                <FullPageText showStar={false}>
                    "Odhaluje hlubiny a skryté věci, poznává to, co je ve tmě, a světlo s&nbsp;ním bydlí." <br /><i>Daniel 2, 22</i>
                </FullPageText>
            </div>
        );
    }
}

export default CommunityPage;