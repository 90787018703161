import React, { Component } from 'react';
import { translate } from 'react-translate'
import { Link } from 'react-router-dom';
import Textblock from '../Components/Textblock';
import MassInfo from '../Components/MassInfo';
import PrayerInfo from '../Components/PrayerInfo';
import FullPageText from '../Components/FullPageText';
import VincencImage from '../Images/klaster-vincenc-500-750.jpg';
import NadvoriImage from '../Images/nadvori.jpg';
import ComplexGarden1Image from '../Images/complex-garden1.jpg';
import ChurchImage from '../Images/church.jpg';
import Church2Image from '../Images/church2.jpg';
import MonasteryFromBehindImage from '../Images/monastery-from-behind.jpg';
import ErbImage from '../Images/erb-ditrichstejnu.jpg';
import Easter from '../Images/velikonoce.jpg';
import Christmas from '../Images/christmas-betlehem.jpg';
import Roof from '../Images/strecha/strecha.jpg';
import Nespory from '../Images/homepage/klaster.jpg';
import SbirkaLink from '../Images/sbirka/sbirka-link.jpg';
import SbirkaVideoBanner from '../Images/sbirka/sbirka-video-banner.jpg';

class Home extends Component {
    render() {
        return (
            <div className="main-content">

            {/*<div className="main-content" style={{backgroundPosition: 'center center' }}>
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-12 main-hp-message">
                            <Textblock codename="main_hp_message" language={this.props.language} />
                        </div>
                    </div>
                </div>
            </div>

             <div className="main-content" style={{background: 'url(' + Easter + ')', backgroundPosition: 'center center', backgroundSize: 'cover', color: 'white', textShadow: '0px 0px 30px #000000', position: 'relative' }}>
                <div style={{position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', backgroundColor: 'black', opacity: '0.3'}}></div>
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            <h1 className="main-title" style={{color: 'white' }}>
                                Velikonoce 2019 v klášterním kostele
                            </h1>
                            <ul className="homepage-program">
                                <li>
                                    18. 4. <strong>Zelený čtvrtek</strong>, 6:30 temné hodinky, 16:00 mše svatá
                                </li>
                                <li>
                                    19. 4. <strong>Velký pátek</strong>, 7:00 temné hodinky, 10:00 křížová cesta, 15:00 obřady na památku umučení Páně
                                </li>
                                <li>
                                    20. 4. <strong>Bílá sobota</strong>, 7:00 temné hodinky, <strong>20:30 Vigilie slavnosti Zmrtvýchvstání Páně</strong>
                                </li>
                                <li>
                                    <strong>21. 4. Zmrtvýchvstání Páně, mše 8:45 a 18:00</strong>
                                </li>
                                <li>
                                    <Link to={`/ohlasky/kvetna_nedele`}>...více v ohláškách</Link>
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>
            */}
            {/*
                <div className="main-content" style={{background: 'url(' + Christmas + ')', backgroundPosition: 'center center', backgroundSize: 'cover', color: 'white', textShadow: '0px 0px 30px #000000', position: 'relative' }}>
                    <div className="op-container animatedParent animateOnce">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <h1 className="main-title" style={{color: "white"}}>
                                    Vánoce 2023 v klášterním kostele
                                </h1>
                                <ul className="homepage-program">
                                    <li>
                                        <strong>24.12. Štědrý den, mše 8.45 a v 22.00</strong>
                                    </li>
                                    <li><strong>25.12. Slavnost Narození Páně, mše 8.45 a v 18.00</strong></li>
                                    <li>26.12. Svátek sv. Štěpána, prvomučedníka, mše 8.45</li>
                                    <li>27.12. Svátek sv. Jana, apoštola a evangelisty, mše 7.00 (žehnání vína)</li>
                                    <li>28.12. Svátek sv. Betlémských dětí, mučedníků, mše 7.00</li>
                                    <li>31. 12. Svátek Svaté Rodiny (sv. Silvestr), mše 8.45 a v 18.00</li>
                                    <li>1. 1. 2024 Slavnost Matky Boží Panny Marie, Nový rok, mše 8.45 a v 18.00</li>
                                    <li>6. 1. 2024 Slavnost Zjevení Páně, mše 7.00, 15.30 s žehnáním vody, křídy a kadidla</li>
                                    <li>
                                        <Link to={`/ohlasky/n4__nedele_adventni___stedry_den_2023`}>...více v ohláškách</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>*/
                }
                {
                    <div>

                        <div className="main-content-normal-height" style={{ background: 'url(' + Roof + ')', backgroundPosition: 'center center', backgroundSize: 'cover', color: 'white', textShadow: '0px 0px 30px #000000', position: 'relative' }}>
                            <div className="op-container animatedParent animateOnce">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12">
                                        <h1 className="main-title" style={{ color: "white" }}>
                                            Rekonstrukce střechy klášterního kostela
                                        </h1>
                                        <div className="homepage-program">
                                            Vzhledem k špatnému stavu střechy klášterního kostela nám byla doporučena její celková rekonstrukce. Prosíme o finanční podporu.<br />
                                            Přispívat můžete na číslo účtu<br />
                                            ČSOB 292533764/0300<br />
                                            IBAN CZ48 0300 0000 0002 9253 3764<br />
                                            BIC CEKOCZPP<br /><br />
                                            Na tento účel jsme společně s nadačním fondem Teovia a jejich platformou donace.cz založili veřejnou sbírku a na opravu je určena vždy i jedna nedělní sbírka v měsíci.<br />
                                            Všem dárcům upřímné Pán Bůh zaplať!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="op-container animatedParent animateOnce">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4" style={{paddingTop: '30px', textAlign: 'center'}}>
                                    <a href="https://donace.cz/projekty/sbirka-na-opravu-strechy-klasterniho-kostela-nanebevzeti-panny-marie-v-uherskem-brode/"><img src={SbirkaLink} alt="odkaz na sbírku" width="356" height="471" /></a>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                    <iframe src="https://donace.cz/projekty/sbirka-na-opravu-strechy-klasterniho-kostela-nanebevzeti-panny-marie-v-uherskem-brode/widget" width="360" height="475" style={{ display: 'block', margin: '30px auto' }}></iframe>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4" style={{paddingTop: '30px', textAlign: 'center'}}>
                                    <a href="https://www.youtube.com/watch?v=Hab3i3rDjas"><img src={SbirkaVideoBanner} alt="video" width="356" height="471" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
            }
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Klášterní kostel
                                <br />Uherský Brod
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                    ...nyní užívají mnišky řádu bratří kazatelů – řeholní společenství v rámci římskokatolické církve. Jsou také
                                    známy jako dominikánky podle sv. Dominika Guzmána.
                                    První dům dominikánských mnišek založil svatý Dominik v Prouille ve Francii r. 1206.
                                </p>
                                <p>
                                    Kostel a konvent dominikánského řádu byly původně v gotickém slohu.
                                    Nová barokní přestavba kostela byla provedena v 17. století za přispění hraběnky Eleonory z Kounic.
                                </p>
                                <p>
                                    Největší cenností chrámu je obraz Černé Madony z přelomu 17. a 18. století, který je inspirován původním obrazem. 
                                    Podle živé legendy byl brodský obraz uloupen za nejasných okolností 
                                    na začátku 14. století (pravděpodobně za vpádu Matúša Čáka Trenčianského)
                                    a dostal se z Uherského Brodu do Čenstochové v Polsku.
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={VincencImage} width="500px" alt="Vincenc" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="margin-top-50 relative animatedParent animateOnce gray-content-container" style={{ "backgroundImage": "url(" + NadvoriImage + ")" }}>
                    <div className="gray-content animated fadeInLeftShort">
                        Původní klášterní kostel pro dominikány s charakteristickým dlouhým presbytářem, vybudovaným patrně koncem 13. a začátkem
                        14. století, je situován na severovýchodě při obvodu historického jádra města. Podle své polohy ve
                        městě je také nazýván Horním kostelem a v době předhusitské byl mnohem vyšší než nynější trojlodní
                        chrám a v průčelí měl mohutné věže.
                    </div>
                </div>
                <MassInfo language={this.props.language} />
               
                <div className="margin-top-50 relative animatedParent animateOnce gray-content-container" style={{ "backgroundImage": "url(" + Nespory + ")" }}>
                    <div className="gray-content animated fadeInLeftShort">
                        Kostel je zpravidla otevřen i v průběhu dne. Jste zváni na pravidelnou modlitbu. Fotka z nešpor:
                    </div>
                </div>
                <PrayerInfo language={this.props.language} />
                <div className="full-width-image-container" style={{marginTop: '-50px'}}>
                    <img src={ComplexGarden1Image} alt="zahrada" />
                </div>
                <FullPageText showStar={false}>
                    Radikální přestavba klášterního komplexu probíhala v letech <span style={{ whiteSpace: "nowrap" }}>1656–1673</span>.  
                    Tuto skutečnost připomíná rodový <a data-fancybox="gallery" href={ErbImage} data-fancybox-group="erb">erb Dietrichštejnů</a> umístěný 
                    nad raně barokním portálem hlavního vstupu do&nbsp;kostela
                </FullPageText>
                <div className="full-width-image-container">
                    <img src={Church2Image} alt="kostel a nádvoří" />
                </div>
              
                <FullPageText showStar={false}>
                    Poslední násilí se ve zdech tohoto kláštera a kostela odehrálo v 50. letech minulého století, 
                    kdy došlo po nařízení komunistické vlády ke zrušení mužských řádů.
                    {' '}
                    <Link to={`/vyhnani-dominikanu-z-uherskeho-brodu-v-roce-1950`}>Dominikáni tedy museli klášter opět opustit</Link>.
                    Vrátit se mohli až po událostech v roce 1989.
                </FullPageText>
                <div className="full-width-image-container">
                    <img src={ChurchImage} alt="kostel se sochou sv. Vincence" />
                </div>
                <FullPageText showStar={true}>
                    "Na mysli nám tane tvé milosrdenství, Bože, zde, uprostřed tvého chrámu."<br />
                    <i>Žalm 48,15</i>
                </FullPageText>
                
                <div className="full-width-image-container">
                    <img src={MonasteryFromBehindImage} alt="pohled z klášterní zahrady" />
                </div>
            </div>
        );
    }
}

export default translate("Home")(Home);