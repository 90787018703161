import React, { Component } from 'react';
import Iframe from 'react-iframe';
import FullPageText from '../Components/FullPageText';
import ZdislavaBellImage from '../Images/zdislava-bell.jpg';
import SmallBellImage from '../Images/bell-small.jpg';
import MariaBellImage from '../Images/zvony/maria.jpg';
import ZdislavaWholeBellImage from '../Images/zvony/zdislava.jpg';
import PeterAndPaulBellImage from '../Images/zvony/petr-a-pavel.jpg';
import MariaBellImageSmall from '../Images/zvony/small/maria.jpg';
import ZdislavaWholeBellImageSmall from '../Images/zvony/small/zdislava.jpg';
import PeterAndPaulBellImageSmall from '../Images/zvony/small/petr-a-pavel.jpg';

import MariaClapperImage from '../Images/zvony/maria-srdce.jpg';

import ClockImage1 from '../Images/zvony/hodiny.jpg';
import ClockImage2 from '../Images/zvony/hodiny2.jpg';
import ClockImage1Small from '../Images/zvony/small/hodiny.jpg';
import ClockImage2Small from '../Images/zvony/small/hodiny2.jpg';

class Home extends Component {
    render() {
        return (
                <div className="main-content">
                    <div className="op-container animatedParent animateOnce">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <h1 className="main-title ">
                                    Věže – zvony
                                </h1>
                                <div className="main-perex animated fadeInRightShort">
                                    <p>
                                        V současné době jsou na 2 věžích umístěny 4 zvony. Na jižní věži zvon Maria, který je nejstarší a nejtěžší, na serverní 
                                        pravidelně odbíjí zvony Zdislava a "Petr a Pavel", je zde umístěn i malý zvon "umíráček". 
                                    </p>
                                    <p>
                                        Na věžích prý byly v letech 1354–1419 umístěny bicí hodiny a dva zvony, zničené za husitských válek
                                    </p>
                                    <p>
                                        Starý zvon Panny Marie o váze 2200 kg byl za převora Adriána Kouřimského (1699–1702) doplněn dvěma menšími zvony 
                                        sv. Jana Nepomuckého 150 kg a sv. Justiny 220 kg, které vytvořil Vincenc Jakub Deromet z Vídně. 
                                        Oba zvony byly zrekvírovány v době 1. světové války. Dne 1. 9. 1927 vysvětil benediktýnský opat Petr Hlobil 
                                        dva nové zvony, a to sv. Justinu 1147 kg a Krista Krále 829 kg, které byly ovšem v r. 1942 znovu zabaveny 
                                        pro válečné účely. 
                                    </p>
                                    <div className="text-center star-container">
                                        <div className="star"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                    <img src={SmallBellImage} width="500px" alt="zvon" />
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="full-width-image-container margin-top-50 margin-bottom-50">
                        <img src={ZdislavaBellImage} title="zvon Zdislava" alt="zvon Zdislava" />
                    </div>
                    <div className="op-container animatedParent animateOnce">
                        <div className="row">
                            <div className="col-xs-12 full-page-text">
                                <div className=" margin-top-30 margin-bottom-50">
                                    Z Bartoškovy kroniky známe dva zvony z 16. století. Byly ulity zvonaři Filipem a Janem z Vyškova v letech 1557 a 1573. 
                                    Tyto zvony byly nedlouho potom v roce 1589 zavěšeny na Mikulášem Lorkem nově postavenou kostelní věž chrámu sv. Jana. 
                                    Tyto zvony se do současnosti nedochovaly, poněvadž již v roce 1631 došlo k jejich přelití 
                                    (patrně byly poškozeny během dramatických okamžiků na počátku třicetileté války). Požár roku 1669  nepřečkaly ani další zvony. 
                                    Proto děkan Václav Polontius nechal odlít hned 4 zvony nové. Ty však měly vyměřen ještě kratší osud. 
                                    V ranních hodinách dne 14. 7. roku 1683 zvony bily na poplach před kuruci a toto bylo současně jejich poslední zvonění. 
                                    Všechny čtyři zvony se při požáru roztavily. Bylo potřeba obstarat nové. K dispozici bylo 2 836 liber zvonoviny, 
                                    ke které bylo přikoupeno ještě 60 liber. Nové zvony byly odlévány přímo ve městě, jak svědčí Ležák: 
                                    „Léta Páně 1684. v měsíci junii jsou zvony lété na farní kostel z materie rospuštěnej po shoření kostela a veže novej [...], a že jí na tři zvony málo bylo, něco se přikoupilo a něco od lidí z města všelijakej starej materie od cínu a medie darováno jest. Pec byla u školy městskej v hliněnci udělána. Když pak již všechno vyhotoveno bylo a materie rospuštěna, podle křesťanského obyčeje musica školská litanias lauretanas pro šťastné se podaření a ulití u přítomnosti pana děkana a duchovních spívati a musicírovati počala, zvonař pak materii již rospuštěnou po korýtku do formy v zemi zakopanej pustil, však nešťastně, nebo všechna prorazíce formy hliněné, do země vběhla, až na zem nad sebou hrubú horkostí a silú vypručila a natrhla. Zvonař vidouce to, že jest zle, s lamentatí hrubú hned od pece s hanbú pryč odešel a na tú žalost a škodu pro vyražení vínem dobrú mysl sobě dal. Po ránu pak to místo jest odkryté a našel se ten nedostatek při tom, že pod formú hrubého zvonu cihly syrova uschlé a nevypálené byly, a proto ten spodek materie prorazila a všechna do jednoho balvanu v zemi se rozlila, kterou vydělajíce, rostřískati dal a znovu zase na svou škodu prohlédnutú rospúštěti a léti musel. Však potřeby k tomu zasej jiné všechny znovu dáti se jemu musely, ale na platě nic se jemu za to nepřidalo. Po druhej tedy ty zvony lejíce, všechno šťastně se vydařilo, takže nejlepší tej materie na ty tři zvony [se] dostalo, však nic nezbylo a o málo šlo, že na uši dosti těško se dotáhlo.“  
                                    Největší ze zvonů, které odlil uherský mistr, nese jméno Jan.  Víc než o polovinu menší je zvon svatého Kříže.  Další dva odlité menší zvony se do současnosti již nedochovaly.  
                                    <div className="text-left">
                                        <ul>
                                            <li>
                                                Pamětní kniha města Brodu Uherského, f. 5-6.
                                            </li>
                                            <li>
                                                ZEMEK, Petr. Reformace, protireformace a rozvinutí poreformačního katolictví v Uherském Brodě - křesťanská víra v proměnách času. Uherský Brod: Muzeum J.A. Komenského v Uherském Brodě v nakl. Albert, 2006. ISBN 80-7326-093-x.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="padding-top-50 padding-bottom-50">
                        <div className="video-iframe-wrapper-container">
                            <div className="video-iframe-wrapper">
                                <Iframe width="100%"  url="https://www.youtube.com/embed/Vb-ZD6C6f8s" frameBorder="0" allowFullScreen></Iframe>
                            </div>
                        </div>
                    </div>
                    <div className="padding-top-50 padding-bottom-50">
                        <div className="video-iframe-wrapper-container">
                            <div className="video-iframe-wrapper">
                                <Iframe width="100%"  url="https://www.youtube.com/embed/eC0H0u1iQt8" frameBorder="0" allowFullScreen></Iframe>
                            </div>
                        </div>
                    </div>
                    <div className="padding-top-50 padding-bottom-50">
                        <div className="video-iframe-wrapper-container">
                            <div className="video-iframe-wrapper">
                                <Iframe width="100%"  url="https://www.youtube.com/embed/IbqrdWK5Ymg" frameBorder="0" allowFullScreen></Iframe>
                            </div>
                        </div>
                    </div>
                    <div className="padding-top-50 padding-bottom-50">
                        <div className="video-iframe-wrapper-container">
                            <div className="video-iframe-wrapper">
                                <Iframe width="100%"  url="https://www.youtube.com/embed/fCJ5B-k1ong" frameBorder="0" allowFullScreen></Iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row bells-container">
                        <div className="col-xs-12 col-sm-4 full-width-image-container">
                            Zvon Maria
                            <div data-fancybox="gallery" href={MariaBellImage} data-fancybox-group="bell">
                                <img src={MariaBellImageSmall} alt="Zvon Maria" title="Zvon Maria" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 full-width-image-container">
                            Zvon Petr a Pavel
                            <div data-fancybox="gallery" href={PeterAndPaulBellImage} data-fancybox-group="bell">
                                <img src={PeterAndPaulBellImageSmall} alt="Petr a Pavel" title="Petr a Pavel" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 full-width-image-container">
                            Zvon Zdislava
                            <div data-fancybox="gallery" href={ZdislavaWholeBellImage} data-fancybox-group="bell">
                                <img src={ZdislavaWholeBellImageSmall} alt="Zvon Zdislava" title="Zvon Zdislava" />
                            </div>
                        </div>
                    </div>
                    <FullPageText showStar={false}>
                        Na zvon Maria se ručně zvoní pouze jednou v roce na Růženovou pouť. Větší zátěž není možná kvůli technickému stavu.
                    </FullPageText>
                    <div className="full-width-image-container margin-bottom-30">
                        <img src={MariaClapperImage} alt="Srdce zvonu Maria" title="Srdce zvonu Maria" />
                    </div>
                    <FullPageText showStar={false}>
                        Na severní věži se nacházejí věžní hodiny, opravené v r. 1920 hodinářem Janem Klabačkou 
                        a pocházející z dominikánského kláštera v Olomouci.
                    </FullPageText>
                    <div className="row photo-grid-container">
                        <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ClockImage1} data-fancybox-group="vestry">
                            <img src={ClockImage1Small} alt="Hodinový stroj" />
                        </div>
                        <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ClockImage2} data-fancybox-group="vestry">
                            <img src={ClockImage2Small} alt="Hodinový stroj" />
                        </div>
                        <div className="clearfix"></div>
                    </div>
                   
                   
                </div>
        );
    }
}

export default Home;