import React, { Component } from 'react';
import FullPageText from '../Components/FullPageText';

import HallImage from '../Images/chodba3.jpg';
import TeaserImage from '../Images/pohled-do-cely2.jpg';

import Dominik from '../Images/dominik2.jpg';

import HallViewImage from '../Images/klaster/sal-pruhled.jpg';
import HallCrossImage from '../Images/klaster/sal-kriz.jpg';
import Hall2Image from '../Images/klaster/chodba-prizemi.jpg';
import Hall3Image from '../Images/klaster/chodba-prizemi-2.jpg';
import Hall4CrossImage from '../Images/klaster/krizova-chodba-kriz.jpg';
import HallBackCell1Image from '../Images/klaster/chodba-prizemi-zadni-cela.jpg';
import HallBackCell2Image from '../Images/klaster/chodba-prizemi-zadni-cela2.jpg';
import ChapelCrossImage from '../Images/klaster/kaple.jpg';
import ChapelWholeImage2 from '../Images/klaster/kaple-celek2.jpg';
import ChapelHearthImage from '../Images/klaster/kaple-srdce.jpg';
import ChapelBooksImage from '../Images/klaster/kaple-knihy.jpg';
import ChapelChoirImage from '../Images/klaster/kaple-varhany.jpg';
import StepsToChapelImage from '../Images/klaster/schody-do-kaple.jpg';
import LibraryImage from '../Images/klaster/knihovna.jpg';
import ClauseImage from '../Images/klaster/klauzura.jpg';
import CellImage from '../Images/klaster/cela.jpg';
import WellImage from '../Images/klaster/studna.jpg';

import HallCrossImageSmall from '../Images/klaster/small/sal-kriz.jpg';
import Hall2ImageSmall from '../Images/klaster/small/chodba-prizemi.jpg';
import Hall3ImageSmall from '../Images/klaster/small/chodba-prizemi-2.jpg';
import Hall4CrossImageSmall from '../Images/klaster/small/krizova-chodba-kriz.jpg';
import HallBackCell1ImageSmall from '../Images/klaster/small/chodba-prizemi-zadni-cela.jpg';
import HallBackCell2ImageSmall from '../Images/klaster/small/chodba-prizemi-zadni-cela2.jpg';
import ChapelWholeImage2Small from '../Images/klaster/small/kaple-celek2.jpg';
import ChapelHearthImageSmall from '../Images/klaster/small/kaple-srdce.jpg';
import ChapelBooksImageSmall from '../Images/klaster/small/kaple-knihy.jpg';
import ChapelChoirImageSmall from '../Images/klaster/small/kaple-varhany.jpg';
import StepsToChapelImageSmall from '../Images/klaster/small/schody-do-kaple.jpg';
import LibraryImageSmall from '../Images/klaster/small/knihovna.jpg';
import ClauseImageSmall from '../Images/klaster/small/klauzura.jpg';
import CellImageSmall from '../Images/klaster/small/cela.jpg';

class MonasteryPage extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="op-container animatedParent animateOnce">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <h1 className="main-title ">
                                Klášterní budova
                            </h1>
                            <div className="main-perex animated fadeInRightShort">
                                <p>
                                    ...je přímo propojená s kostelem a má 3 podlaží. 
                                </p>
                                <p>
                                    V klášteře v minulosti nacházeli domov hlavně dominikánští mniši, 
                                    ale kvůli pohnuté historii tomu tak nebylo vždy a klášter několikrát zanikl.
                                    Budova před přestavbou velmi pravděpodobně tvořila uzavřený komplex. 
                                </p>
                                <p>
                                    Vznikala zde růžencová bratrstva – laická sdružení s přísnější organizací po vzoru církevních řeholí.
                                </p>
                                <p>
                                    Od roku 2018 budovu poprvé obývá ženská mnišská větev řádu dominikánů původně sídlící ve Znojmě.
                                </p>
                                <div className="text-center star-container">
                                    <div className="star"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="text-center animated fadeInLeftShort delay-250 col-image">
                                <img src={TeaserImage} width="100%" alt="cela" />
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                {/*<div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6">
                        <div className="full-width-image-container margin-top-50 margin-bottom-50">
                            <img src={Dominik} width="500px" alt="Dominik" />
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 animatedParent animateOnce">
                        <div className="center-parent margin-top-50 margin-bottom-50">
                            <div className="center-content left animated fadeInLeftShort">
                                Klášter historicky patří <br /> <strong>Řádu kazatelů</strong>.<br />
                                Apoštolský způsob života je charakterizován čtyřmi základními prvky:<br /><br />
                                <strong>
                                Společný život.<br />
                                Slavení liturgie. <br />
                                Studium.<br />
                                Služba slova – kázání. <br /></strong>
                            </div>
                        </div>
                    </div>
        </div>*/}
                <div className="margin-top-100 full-width-image-container relative">
                    <img src={Dominik} alt="sv. Dominik" />
                    <div className="blackbox absolute">
                    Obraz svatého Dominika v presbitáři kostela
                    </div>
                </div>
                {/*<div className="margin-top-100 relative">
                    <div className="absolute-black-backgrounddd"></div>
                    <div className="op-container ">
                        <img src={Dominik} alt="Dominik" />
                    </div>
    </div>*/}
                <FullPageText showStar={false}>
                    Klášter historicky patří <br /> <strong>Řádu kazatelů</strong>.<br />
                    Apoštolský způsob života je charakterizován čtyřmi základními prvky:<br /><br />
                    <strong>
                    Společný život.<br />
                    Slavení liturgie. <br />
                    Studium.<br />
                    Služba slova – kázání. <br /></strong>
                </FullPageText>

                <div className="margin-top-100 full-width-image-container">
                    <img src={HallImage} alt="chodba 1. poschodí" />
                </div>
                <FullPageText showStar={false}>
                    Existence kláštera byla ohrožena zejména za josefínských reforem, ale klášterní život se zde, byť v minimálním měřítku, zachoval. 
                </FullPageText>
                <div className="full-width-image-container">
                    <img src={HallViewImage} alt="Sál" />
                </div>
                <FullPageText showStar={false}>
                    Velký rozmach řádového života nastal zejména v první polovině 20. století, ale tento příznivý vývoj byl zastaven v dubnu 1950, 
                    kdy tehdejší Státní bezpečnost v rámci celostátní akce K likvidovala mužské řády. 
                    Objekt byl vyklizen a používán k různým účelům. Nakonec předán SVAZARMU.
                </FullPageText>
             
                <div className="row photo-grid-container">
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={HallCrossImage} data-fancybox-group="vestry">
                        <img src={HallCrossImageSmall} alt="Kříž v sále" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={Hall2Image} data-fancybox-group="vestry">
                        <img src={Hall2ImageSmall} alt="Křížová chodba" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={Hall3Image} data-fancybox-group="vestry">
                        <img src={Hall3ImageSmall} alt="Chodba přízemí" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={Hall4CrossImage} data-fancybox-group="vestry">
                        <img src={Hall4CrossImageSmall} alt="Chodba přízemí" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={HallBackCell1Image} data-fancybox-group="vestry">
                        <img src={HallBackCell1ImageSmall} alt="Chodba přízemí" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={HallBackCell2Image} data-fancybox-group="vestry">
                        <img src={HallBackCell2ImageSmall} alt="Chodba přízemí" />
                    </div>
                    <div className="clearfix"></div>
                </div>

                <FullPageText showStar={false}>
                    V roce 1986 došlo k zpracování studie na rekonstrukci objektu, o tři roky později byl dokončen projekt a v r. 1990 se začalo se stavebním zajištěním objektu, 
                    výměnou krovů, krytiny a celkovou obnovou.
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={ChapelCrossImage} alt="Kříž v kapli" />
                </div>
                <div className="row photo-grid-container">
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ChapelWholeImage2} data-fancybox-group="vestry">
                        <img src={ChapelWholeImage2Small} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ChapelHearthImage} data-fancybox-group="vestry">
                        <img src={ChapelHearthImageSmall} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ChapelBooksImage} data-fancybox-group="vestry">
                        <img src={ChapelBooksImageSmall} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ChapelChoirImage} data-fancybox-group="vestry">
                        <img src={ChapelChoirImageSmall} alt="kaple" />
                    </div>
                    <div className="clearfix"></div>
                </div>
                <FullPageText showStar={false}>
                    Při sondách bylo odkryto několik budov a v podstatě čtyři fáze zástavby. 
                    Nejstarší opevněná zeď je patrně pozůstatkem nějakého starého středověkého ambitu klášterního komplexu, který tvořil uzavřenou část. 
                    Lze předpokládat, že uvnitř byla rajská zahrada - klášterní ambit a křížová chodba. 
                </FullPageText>
                <div className="row photo-grid-container">
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={StepsToChapelImage} data-fancybox-group="vestry">
                        <img src={StepsToChapelImageSmall} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={ClauseImage} data-fancybox-group="vestry">
                        <img src={ClauseImageSmall} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={LibraryImage} data-fancybox-group="vestry">
                        <img src={LibraryImageSmall} alt="kaple" />
                    </div>
                    <div className="col-xs-12 col-sm-6 full-width-image-container" data-fancybox="gallery" href={CellImage} data-fancybox-group="vestry">
                        <img src={CellImageSmall} alt="kaple" />
                    </div>
                    <div className="clearfix"></div>
                </div>
                <FullPageText showStar={false}>
                    "Odhaluje hlubiny a skryté věci, poznává to, co je ve tmě, a světlo s&nbsp;ním bydlí." <br /><i>Daniel 2, 22</i>
                </FullPageText>
                <div className="full-width-image-container margin-bottom-30">
                    <img src={WellImage} alt="Studna v klášterní zahradě" title="Studna v klášterní zahradě" />
                </div>
            </div>
        );
    }
}

export default MonasteryPage;