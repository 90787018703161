import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import App from './LocalizedApp';
import Analytics from 'react-router-ga';

import './css/bootstrap.css';
import './css/helpers.css';
import './css/op-timeline.css';
import './css/op-main.css';
import './css/op-page-transition.css';
import './css/op-page-spinner.css';
import './css/animations.css';

ReactDOM.render((
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <Analytics id="UA-119338692-1" debug>
      <Switch>
        <Route path="/:lang" render={matchProps => <App {...matchProps} />} />
        <Route path="/" render={matchProps => <App {...matchProps} />} />
      </Switch>
      </Analytics>
  </Router>
), document.getElementById("root"));