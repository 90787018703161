import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductList extends Component {

  constructor(props) {
    super(props);

  }

  onChange() {
  }

  render() {
    let counter = 0;

    let products = this.props.products.reduce((result, product, index) => {
     

      let title = product.productName;
      let imageName = product.productImage;
     
    
        
      let link = `/Images/products/${imageName}`;
      let linkbig = `/Images/products-big/${imageName}`;
      // if (window.location.hostname == "localhost")
      // {
      //     link = `/images/products/${imageName}`;
      //     linkbig = `/images/products-big/${imageName}`;
      // }

      result.push(
        <div key={counter++} className="product-item col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <div style={{backgroundImage: "url(" + link + ")"}} className="product-main-image"  />
            <a data-fancybox="gallery" href={linkbig} data-fancybox-group="products">
              <img src={link} alt={title} />
            </a>
              <div className="product-main-title">{title}</div>
              <div className="product-price">{product.price} Kč</div>
        </div>
      );

      return result; 
    }, []);

    return (
      <React.Fragment>
          {products}
        </React.Fragment>
    );
  }
}

export default ProductList;