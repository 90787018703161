import React, { Component } from 'react';
import PhotogalleryStore from '../Stores/Photogallery';
import { Link } from 'react-router-dom';
import photogalleriesJSON from '../Data/newphotogallery.json';

let photogalleryCount = 1000;

let getState = () => {
  return {
    photogalleries: photogalleriesJSON //PhotogalleryStore.getPhotogalleries(photogalleryCount)
  };
};

class Photogalleries extends Component {

  constructor(props) {
    super(props);

    this.state = getState();
    this.onChange = this.onChange.bind(this);
  }

  // componentDidMount() {
  //   PhotogalleryStore.addChangeListener(this.onChange);
  //   PhotogalleryStore.providePhotogalleries(photogalleryCount);
  // }

  // componentWillUnmount() {
  //   PhotogalleryStore.removeChangeListener(this.onChange);
  // }

  onChange() {
    this.setState(getState());
  }

  render() {
    let counter = 0;
    var test = photogalleriesJSON;
    let photogalleries = photogalleriesJSON.items.reduce((result, photogallery, index) => {
      if (index % 4 === 0) {
        result.push(
          <div className="clear" key={counter++}></div>
        );
      }

      let title = photogallery.AlbumName;
      let FolderName = photogallery.FolderName;
      let mainPhotoName = photogallery.MainPhotoName;

      let imageUrl = `/Images/fotky/${FolderName}/small/${mainPhotoName.replace('.JPG', '.jpg')}`;
      // if (window.location.hostname == "localhost")
      // {
      //   imageUrl = `/images/fotky/${FolderName}/small/${mainPhotoName.replace('.JPG', '.jpg')}`;
      // }

      let Url = photogallery.url;
      let link = `/fotogalerie/${photogallery.Url}`;
      
      result.push(
        <div key={counter++} className="photogallery-item">
            <Link to={link}><span style={{backgroundImage: "url(" + imageUrl + ")"}} className="photogallery-main-image"  />
              <span className="photogallery-main-title">{title}</span>
            </Link>
        </div>
      );

      return result; 
    }, []);

    return (
        <div className="photo-galleries-container">
          {photogalleries}
        </div>
    );
  }
}

export default Photogalleries;