import { DeliveryClient, TypeResolver } from 'kentico-cloud-delivery';
// models
import { AboutUs } from './Models/AboutUs';
import { Photogallery } from './Models/Photogallery';
import { HostedVideo } from './Models/HostedVideo';
import { Altar } from './Models/Altar';
import { Text } from './Models/Text';
import { Textblock } from './Models/Textblock';
import { Banns } from './Models/Banns';
const projectId = 'bf6f955f-4312-4649-93a6-dd47ce9fa5dd';
const previewApiKey = "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAidWlkIjogInVzcl8wdkladFJqQ2RLakNKdXEyY3dyMTJDIiwNCiAgImVtYWlsIjogInN0YW5pc2xhdi5rYWRsY2lrQHB1eGRlc2lnbi5jeiIsDQogICJwcm9qZWN0X2lkIjogImJmNmY5NTVmLTQzMTItNDY0OS05M2E2LWRkNDdjZTlmYTVkZCIsDQogICJqdGkiOiAiMVZDam9LbWN6WXp4dmF5cSIsDQogICJ2ZXIiOiAiMS4wLjAiLA0KICAiZ2l2ZW5fbmFtZSI6ICJTdGFuaXNsYXYiLA0KICAiZmFtaWx5X25hbWUiOiAiS2FkbMSNw61rIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.-Qr-DCUv3Bgklutz28Efxj6ZnJtNSGYGrGpaXXoBZWs";

// configure type resolvers
let typeResolvers = [
  new TypeResolver('about_us', () => new AboutUs()),
  new TypeResolver('photogallery', () => new Photogallery()),
  new TypeResolver('hosted_video', () => new HostedVideo()),
  new TypeResolver('altar', () => new Altar()),
  new TypeResolver('text', () => new Text()),
  new TypeResolver('textblock', () => new Textblock()),
  new TypeResolver('banns', () => new Banns()),
];


function isPreview() {
  return false;
  return previewApiKey !== "";
}

let Client = new DeliveryClient({
      projectId: projectId,
      typeResolvers: typeResolvers,
      enablePreviewMode: isPreview(),
      previewApiKey: previewApiKey
    }
)

export default Client;