import { ContentItem } from 'kentico-cloud-delivery';
import { resolveContentLink } from '../Utilities/ContentLinks';

export class Altar extends ContentItem {
   constructor(){
        super({
            propertyResolver: ((fieldName) => {
                
                if (fieldName === 'title'){
                    return 'title';
                }

                if (fieldName === 'description'){
                    return 'description';
                }

                if (fieldName === 'teaserimage'){
                    return 'teaserImage';
                }

                if (fieldName === 'mainimage'){
                    return 'mainImage';
                }

                if (fieldName === 'longdescription'){
                    return 'longDescription';
                }

                if (fieldName === 'gallery'){
                    return 'gallery';
                }

                if (fieldName === 'urlpattern'){
                    return 'urlPattern';
                }
            }),
            linkResolver: (link) => resolveContentLink(link)
        })    
    }
}
